// import { saveAs } from 'file-saver';
// import { parse } from 'json2csv';
// import * as XLSX from 'xlsx';

// const downloadCSV = (data) => {
//   const csv = parse(data);
//   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//   saveAs(blob, 'report.csv');
// };

// const downloadJSON = (data) => {
//   const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json;charset=utf-8;' });
//   saveAs(blob, 'report.json');
// };

// const downloadXML = (data) => {
//   const xml = `<root>${data.map(item => `
//     <row>
//       ${Object.entries(item).map(([key, value]) => `<${key}>${value}</${key}>`).join('')}
//     </row>`).join('')}
//   </root>`;
//   const blob = new Blob([xml], { type: 'application/xml;charset=utf-8;' });
//   saveAs(blob, 'report.xml');
// };

// const downloadTXT = (data) => {
//   const txt = data.map(row => Object.values(row).join('\t')).join('\n');
//   const blob = new Blob([txt], { type: 'text/plain;charset=utf-8;' });
//   saveAs(blob, 'report.txt');
// };

// const downloadSQL = (data) => {
//   const tableName = 'report_table';
//   const columns = Object.keys(data[0]).join(', ');
//   const values = data.map(row => `(${Object.values(row).map(value => `'${value}'`).join(', ')})`).join(',\n');
//   const sql = `INSERT INTO ${tableName} (${columns}) VALUES \n${values};`;
//   const blob = new Blob([sql], { type: 'application/sql;charset=utf-8;' });
//   saveAs(blob, 'report.sql');
// };

// const downloadExcel = (data) => {
//   const worksheet = XLSX.utils.json_to_sheet(data);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
//   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//   const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
//   saveAs(blob, 'report.xlsx');
// };

// export { downloadCSV, downloadJSON, downloadXML, downloadTXT, downloadSQL, downloadExcel };
import { saveAs } from 'file-saver';
import { parse } from 'json2csv';
import * as XLSX from 'xlsx';

const downloadCSV = (data, plantName, formattedDate) => {
  const csv = parse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const filename = `${plantName} Report_${formattedDate}.csv`;
  saveAs(blob, filename);
};

const downloadJSON = (data, plantName, formattedDate) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json;charset=utf-8;' });
  const filename = `${plantName} Report_${formattedDate}.json`;
  saveAs(blob, filename);
};

// const downloadXML = (data, plantName, formattedDate) => {
//   const xml = `<root>${data.map(item => `
//     <row>
//       ${Object.entries(item).map(([key, value]) => `<${key}>${value}</${key}>`).join('')}
//     </row>`).join('')}
//   </root>`;
//   const blob = new Blob([xml], { type: 'application/xml;charset=utf-8;' });
//   const filename = `${plantName} Report_${formattedDate}.xml`;
//   saveAs(blob, filename);
// };
const downloadXML = (data, plantName, formattedDate) => {
    // Function to escape special XML characters
    const escapeXML = (str) => {
      return String(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/'/g, '&apos;')
        .replace(/"/g, '&quot;');
    };
  
    // Extract headers (field names) from the first data item
    const headers = Object.keys(data[0]);
  
    // Construct XML
    const xml = `
      <tabledata>
        <fields>
          ${headers.map(header => `<field>${escapeXML(header)}</field>`).join('\n')}
          ${data.flatMap(item => 
            Object.values(item).map(value => `<field>${escapeXML(value)}</field>`)
          ).join('\n')}
        </fields>
        <data/>
      </tabledata>
    `;
  
    // Create Blob and set filename
    const blob = new Blob([xml], { type: 'application/xml;charset=utf-8;' });
    const filename = `${plantName} Report_${formattedDate}.xml`;
    saveAs(blob, filename);
  };
  
const downloadTXT = (data, plantName, formattedDate) => {
  const txt = data.map(row => Object.values(row).join('\t')).join('\n');
  const blob = new Blob([txt], { type: 'text/plain;charset=utf-8;' });
  const filename = `${plantName} Report_${formattedDate}.txt`;
  saveAs(blob, filename);
};

const downloadSQL = (data, plantName, formattedDate) => {
  const tableName = 'report_table';
  const columns = Object.keys(data[0]).join(', ');
  const values = data.map(row => `(${Object.values(row).map(value => `'${value}'`).join(', ')})`).join(',\n');
  const sql = `INSERT INTO ${tableName} (${columns}) VALUES \n${values};`;
  const blob = new Blob([sql], { type: 'application/sql;charset=utf-8;' });
  const filename = `${plantName} Report_${formattedDate}.sql`;
  saveAs(blob, filename);
};

const downloadExcel = (data, plantName, formattedDate) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
  const filename = `${plantName} Report_${formattedDate}.xlsx`;
  saveAs(blob, filename);
};

export { downloadCSV, downloadJSON, downloadXML, downloadTXT, downloadSQL, downloadExcel };
