import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SolarTable.css'; 

const monthMapping = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};

const RECTable = ({ year,selectedPlant }) => {
  const [monthlyValues, setMonthlyValues] = useState({});
  const [soldValues, setSoldValues] = useState({});
  const [yearlyValue, setYearlyValue] = useState('');
  const [yearToDateValue, setYearToDateValue] = useState('');
  
  const calculateYearlyValue = (values) => {
    const total = Object.values(values).reduce((acc, value) => {
      const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
      return acc + numericValue;
    }, 0);
    return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleMonthlyChange = (month, value) => {
    setMonthlyValues((prevValues) => ({
      ...prevValues,
      [month]: value,
    }));
  };

  const handleSoldChange = (month, value) => {
    setSoldValues((prevValues) => ({
      ...prevValues,
      [month]: value,
    }));
  };

  // const plantId = "Sweelee";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`/fmsolaryield`, {
          plant: selectedPlant,
          year: year.toString(),
        });
  
        console.log("Response Data:", response.data);
  
        if (response.data && Array.isArray(response.data)) {
          const pvYieldData = response.data.reduce((acc, item) => {
            const statisticalPeriod = item['StatisticalPeriod'];
            if (statisticalPeriod && typeof statisticalPeriod === 'string') {
              const month = statisticalPeriod.split('-')[1];
              const monthName = monthMapping[month];
              if (monthName) {
                const yieldInMW = (parseFloat(item['TotalInverterYield']) / 1000).toFixed(2) || 0;
                acc[monthName] = yieldInMW.toLocaleString(); 
              }
            }
            return acc;
          }, {});

          setMonthlyValues(pvYieldData);
  
          const totalYearly = calculateYearlyValue(pvYieldData);
          setYearlyValue(totalYearly);
  
          if (year === 2024) {
            const previousYearTotal = "53.02";
            const previousYearTotalNumeric = parseFloat(previousYearTotal.replace(/,/g, '')) || 0;
            const totalYearlyNumeric = parseFloat(totalYearly.replace(/,/g, '')) || 0;
  
            const yearToDateTotal = previousYearTotalNumeric + totalYearlyNumeric;
            setYearToDateValue(yearToDateTotal.toLocaleString());
          } else {
            setYearToDateValue(totalYearly);
          }
        } else {
          console.error("Response data is not in expected array format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching the data!", error);
      }
    };
  
    fetchData();
  }, [year,selectedPlant]);

 
  const getSoldValue = (month) => {
    const yearString = year.toString();
    if (yearString === '2023' && (month !== 'Sep' && month !== 'Oct' && month !== 'Nov' && month !== 'Dec')) {
      return '0'; 
    }
    
    return soldValues[month] || '0'; 
  };
  
  const glitterAnimationStyle = `
  @keyframes glitter {
    0% { background-position: 0 0; }
    50% { background-position: 100% 0; }
    100% { background-position: 0 0; }
  }

  .glitterRow th {
    // background: linear-gradient(90deg, #fff, #ffeb3b, #fff);
    background: linear-gradient(90deg, #32CD32, #7FFF00, #32CD32);
    background-size: 200% 100%;
    animation: glitter 3s infinite;
    color: #fff;
  }
    
  .glitterRows  {
    // background: linear-gradient(90deg, #fff, #ffeb3b, #fff);
    background: linear-gradient(90deg, #32CD32, #7FFF00, #32CD32);
    background-size: 200% 100%;
    animation: glitter 3s infinite;
    color: #fff;
  }
`;

const GlitterEffectStyles = () => (
  <style>
    {glitterAnimationStyle}
  </style>
);



  return (
    <div>
      <h5 style={{ fontWeight: 'bold' }}>REC Details ({year})</h5>
      <GlitterEffectStyles />
      <table className="solar-table">
        <thead>
          <tr>
            <th colSpan="3" className="solar-header" style={{backgroundColor:"grey",color:"white"}}>Monthly</th>
            <th colSpan="2" className="solar-header" style={{backgroundColor:"grey",color:"white"}}>Yearly</th>
            <th colSpan="3" className="solar-header" style={{backgroundColor:"grey",color:"white"}}>Year to Date  <br /> ({year === '2023' || year === 2023 ? '1 Year' : year === '2024' || year === 2024 ? '2 Years' : ''})</th>
          </tr>
          <tr>
            <th>Period</th>
            <th className="glitterRows">Volume</th>
            <th>Sold</th>
            <th className="glitterRows">Volume</th>
            <th>Sold</th>
            <th className="glitterRows">Volume</th>
            <th>Sold</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Jan</td>
            <td>
              <input style={{color:"green",fontWeight:"bold"}}
                type="text"
                value={monthlyValues.Jan || ''}
                onChange={(e) => handleMonthlyChange('Jan', e.target.value)}
              />
            </td>
            <td>
              <input style={{color:"red",fontWeight:"bold"}}
                type="text"
                value={getSoldValue('Jan')}
                onChange={(e) => handleSoldChange('Jan', e.target.value)}
              />
            </td>
            <td rowSpan="12"><input  style={{color:"green",fontWeight:"bold"}} type="text" value={yearlyValue} readOnly /></td>
            <td rowSpan="12">
              <input type="text" style={{color:"red",fontWeight:"bold"}} value="0" readOnly />
            </td>
            <td rowSpan="12">
              <input type="text"  style={{color:"green",fontWeight:"bold"}} value={yearToDateValue} readOnly />
            </td>
            <td rowSpan="12">
              <input type="text"  style={{color:"red",fontWeight:"bold"}} value="0" readOnly />
            </td>
            <td rowSpan="12">
              <input type="text"  style={{color:"green",fontWeight:"bold"}} value={yearToDateValue} readOnly />
            </td>
          </tr>
          {['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => (
            <tr key={month}>
              <td >{month}</td>
              <td>
                <input style={{color:"green",fontWeight:"bold"}}
                  type="text"
                  value={monthlyValues[month] || ''}
                  onChange={(e) => handleMonthlyChange(month, e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text" style={{color:"red",fontWeight:"bold"}}
                  value={getSoldValue(month)}
                  onChange={(e) => handleSoldChange(month, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RECTable;
