// import React from 'react';
// import { useTable } from 'react-table';
// import './DataTable.css' 

// const DataTableComponent = ({ columns, data }) => {
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow
//   } = useTable({ columns, data });

//   return (
//     <div className="table-container">
//       <div className="table-responsive">
//         <table {...getTableProps()} className="table table-striped table-bordered">
//           <thead>
//             {headerGroups.map(headerGroup => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map(column => (
//                   <th {...column.getHeaderProps()}>{column.render('Header')}</th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {rows.map(row => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map(cell => (
//                     <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                   ))}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default DataTableComponent;



import React from 'react';
import './DataTable.css'; 

const DataTableComponent = ({ columns, data, highlightedRows }) => {
  return (
    <table id="table" >
      <thead>
        <tr>
          {columns.map(column => (
            <th key={column.Header} data-field={column.accessor}>
              {column.Header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            className={highlightedRows.includes(rowIndex) ? 'highlight-red' : ''}
          >
            {columns.map(column => (
              <td key={column.accessor}>{row[column.accessor]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTableComponent;

