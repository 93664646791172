




// import React, { useEffect, useState,useCallback } from 'react';
// import axios from 'axios';
// import SolarImage from '../../../img/solar.jpg';
// import GridImage from '../../../img/gridfinal.jpg';
// import LoadImage from '../../../img/Load.png';


// const SolarPanelDiagram = ({plant,date}) => {

//   const [outputPower, setOutputPower] = useState(0);
//   const [dailyEnergy, setDailyEnergy] = useState(0);
//   const [irradiance, setIrradiance] = useState(0); 
//   const [dailyIrradianceMJ, setDailyIrradianceMJ] = useState(0); // For MJ
   

// const fetchDailyEnergy = useCallback(async () => {
//   try {
//       const response = await axios.post('/v1/overview/station-real-kpi/', {
//           plant: plant,
//           timeZone: date,
//       });
//       setDailyEnergy(response.data.data.dailyEnergy);
//   } catch (error) {
//       console.error('There was an error fetching the data!', error);
//       setDailyEnergy(0);
//   }
// }, [plant, date]); 

// useEffect(() => {
//   setDailyEnergy(0);
//   fetchDailyEnergy();
//   const intervalId = setInterval(fetchDailyEnergy, 300000); 
//   return () => clearInterval(intervalId);
// }, [fetchDailyEnergy]);


  
//     const fetchPowerData = useCallback(async () => {
//       try {
//         const response = await fetch('/api/latest/powerout', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             Date: date,
//             plant: plant,
//           }),
//         });
        
//         const data = await response.json();
//         if (data.success) {
//           setOutputPower(data.data.latestPowerOut);
//         }
//       } catch (error) {
//         console.error('Error fetching output power:', error);
//         setOutputPower(0);
//       }
    
//   }, [plant,date]);

//   useEffect(() => {
//     setOutputPower(0);
//     fetchPowerData();
//     const intervalId = setInterval(fetchPowerData, 300000); 
//     return () => clearInterval(intervalId);
//   }, [fetchPowerData]);



//     const fetchDailyIrrad = useCallback(async () => {
//       try {
//         const response = await fetch('/api/dailyirrad', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             D: date,
//             plant: plant,
//           }),
//         });
        
//         const data = await response.json();
      
//         if (data && data.dailyIrradianceMJ !== undefined && data.latestIrradMJ !== undefined) 
//            {
//           setDailyIrradianceMJ(data.dailyIrradianceMJ);  
//           setIrradiance(data.latestIrradMJ * 2); 
//         }
//       } catch (error) {
//         console.error('Error fetching output power:', error);
//       }
    
//   }, [plant, date]);

  
//   useEffect(() => {
//     fetchDailyIrrad();
//     const intervalId = setInterval(fetchDailyIrrad, 300000); 
//     return () => clearInterval(intervalId);

//   },[fetchDailyIrrad]);


//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" className='svg-diagram' viewBox="0 0 400 200">

//      {/* panel to grid */}
//       <image x="270" y="120" width="140" height="62" href={SolarImage} />
   
//       <path id="path-grid" d="M 300 125 L 240 95" stroke="#000" strokeWidth="0.1" fill="transparent" />
//       <text x="380" y="170" fontFamily="Arial" fontSize="10">PV</text>

     
//       <text x="330" y="70" fontFamily="Arial" fontSize="10"> Daily Yield
//       <tspan x="330" y="85"fontSize="10" fontWeight="bold">{dailyEnergy} kWh</tspan>
//       </text>

//       <text x="330" y="98" fontFamily="Arial" fontSize="10"> Output power 
//         <tspan x="330" y="110"fontSize="10" fontWeight="bold">{outputPower} kW</tspan></text>
      
//       <image x="160" y="23" width="90" height="75" href={GridImage} preserveAspectRatio="xMidYMid meet" style={{ imageRendering: 'crisp-edges' }} />
//       <line x1="210" y1="90" x2="100" y2="160" stroke="#000" strokeWidth="0.1" markerEnd="url(#arrowhead)" />
//       <text x="250" y="28" fontFamily="Arial" fontSize="10">Grid</text>
     
//       <image x="35" y="110" width="70" height="110" href={LoadImage} />
//       <path id="path-load" d="M 320 175 Q 220 180 110 180" stroke="#000" strokeWidth="0.1" fill="transparent"/>
//       <text x="6" y="180" fontFamily="Arial" fontSize="10">Load</text>
     
      
//       {/* <g>
//         <path d="M 0 0 L -16 -9" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
//         <animateMotion repeatCount="indefinite" dur="5s">
//           <mpath href="#path-grid" />
//         </animateMotion>
//       </g> */}

      
//       <g>
//         <path d="M 0 0 L -16 0" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
//         <animateMotion repeatCount="indefinite" dur="5s">
//           <mpath href="#path-load" />
//         </animateMotion>
//       </g>

//       <defs>
//         <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto">
//           <polygon points="0 0, 10 3.5, 0 7" fill="orange" />
//         </marker>
//       </defs>
//     </svg>
//   );
// };

// export default SolarPanelDiagram;







import React, { useEffect, useState,useCallback } from 'react';
import axios from 'axios';
import SolarImage from '../../../img/solar.jpg';
import GridImage from '../../../img/gridfinal.jpg';
import LoadImage from '../../../img/Load.png';


const SolarPanelDiagram = ({plant,date}) => {

  const [outputPower, setOutputPower] = useState(0);
  const [dailyEnergy, setDailyEnergy] = useState(0);
  const [irradiance, setIrradiance] = useState(0); 
  const [dailyIrradianceMJ, setDailyIrradianceMJ] = useState(0); // For MJ
   

const fetchDailyEnergy = useCallback(async () => {
  try {
      const response = await axios.post('/v1/overview/station-real-kpi/', {
          plant: plant,
          timeZone: date,
      });
      setDailyEnergy(response.data.data.dailyEnergy);
  } catch (error) {
      console.error('There was an error fetching the data!', error);
      setDailyEnergy(0);
  }
}, [plant, date]); 

useEffect(() => {
  setDailyEnergy(0);
  fetchDailyEnergy();
  const intervalId = setInterval(fetchDailyEnergy, 300000); 
  return () => clearInterval(intervalId);
}, [fetchDailyEnergy]);


  
    const fetchPowerData = useCallback(async () => {
      try {
        const response = await fetch('/api/latest/powerout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Date: date,
            plant: plant,
          }),
        });
        
        const data = await response.json();
        if (data.success) {
          setOutputPower(data.data.latestPowerOut);
        }
      } catch (error) {
        console.error('Error fetching output power:', error);
        setOutputPower(0);
      }
    
  }, [plant,date]);

  useEffect(() => {
    setOutputPower(0);
    fetchPowerData();
    const intervalId = setInterval(fetchPowerData, 300000); 
    return () => clearInterval(intervalId);
  }, [fetchPowerData]);



    const fetchDailyIrrad = useCallback(async () => {
      try {
        const response = await fetch('/api/dailyirrad', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            D: date,
            plant: plant,
          }),
        });
        
        const data = await response.json();
      
        if (data && data.dailyIrradianceMJ !== undefined && data.latestIrradMJ !== undefined) 
           {
          setDailyIrradianceMJ(data.dailyIrradianceMJ);  
          setIrradiance(data.latestIrradMJ * 2); 
        }
      } catch (error) {
        console.error('Error fetching output power:', error);
      }
    
  }, [plant, date]);

  
  useEffect(() => {
    fetchDailyIrrad();
    const intervalId = setInterval(fetchDailyIrrad, 300000); 
    return () => clearInterval(intervalId);

  },[fetchDailyIrrad]);


  return (
<svg xmlns="http://www.w3.org/2000/svg" className='svg-diagram' viewBox="0 0 400 200">
  <image x="270" y="120" width="140" height="62" href={SolarImage} />
  {/* <path id="path-grid" d="M 300 125 L 240 95" stroke="#000" strokeWidth="0.1" fill="transparent" /> */}
  
  <text x="380" y="170" fontFamily="Arial" fontSize="10">PV</text>
  
  <text x="330" y="70" fontFamily="Arial" fontSize="10"> Daily Yield
    <tspan x="330" y="85" fontSize="10" fontWeight="bold">{dailyEnergy} kWh</tspan>
  </text>
  
  <text x="330" y="98" fontFamily="Arial" fontSize="10"> Output power 
    <tspan x="330" y="110" fontSize="10" fontWeight="bold">{outputPower} kW</tspan>
  </text>
  
  <image x="160" y="23" width="90" height="75" href={GridImage} preserveAspectRatio="xMidYMid meet" style={{ imageRendering: 'crisp-edges' }} />
  
  <path id="grid-load" d="M 220 110 L 150 156"  strokeWidth="0.1" fill="transparent" markerEnd="url(#arrowhead)" />
  
  <path id="load-grid" d="M 90 180 L 210 100" stroke="#000" strokeWidth="0.1" fill="transparent" markerEnd="url(#arrowhead)" orient="auto-start-reverse" />
  
  <text x="250" y="28" fontFamily="Arial" fontSize="10">Grid</text>
  
  <image x="35" y="110" width="70" height="110" href={LoadImage} />
  <path id="path-load" d="M 320 175 Q 220 180 110 180" stroke="#000" strokeWidth="0.1" fill="transparent"/>
  <text x="6" y="180" fontFamily="Arial" fontSize="10">Load</text>
  
  {/* <g>
    <path d="M 0 -10 L -16 0" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
    <animateMotion repeatCount="indefinite" dur="5s">
      <mpath href="#grid-load" />
    </animateMotion>
  </g>
   */}

<g>
    <path d="M 10 -15 L -5 -5" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
    <animateMotion repeatCount="indefinite" dur="5s">
      <mpath href="#grid-load" />
    </animateMotion>
  </g>
  
  <g>
    <path d="M 0 -10 L 12 -20" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
    <animateMotion repeatCount="indefinite" dur="5s">
      <mpath href="#load-grid" />
    </animateMotion>
  </g>
  
  <g>
    <path d="M 0 0 L -16 0" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
    <animateMotion repeatCount="indefinite" dur="5s">
      <mpath href="#path-load" />
    </animateMotion>
  </g>

  <defs>
    <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="5" refY="3.5" orient="auto">
      <polygon points="0 0, 10 3.5, 0 7" fill="orange" />
    </marker>
  </defs>
</svg>


    // <svg xmlns="http://www.w3.org/2000/svg" className='svg-diagram' viewBox="0 0 400 200">

    //   <image x="270" y="120" width="140" height="62" href={SolarImage} />
    //   <path id="path-grid" d="M 300 125 L 240 95" stroke="#000" strokeWidth="0.1" fill="transparent" />
     
    //   <text x="380" y="170" fontFamily="Arial" fontSize="10">PV</text>

     
    //   <text x="330" y="70" fontFamily="Arial" fontSize="10"> Daily Yield
    //   <tspan x="330" y="85"fontSize="10" fontWeight="bold">{dailyEnergy} kWh</tspan>
    //   </text>

    //   <text x="330" y="98" fontFamily="Arial" fontSize="10"> Output power 
    //     <tspan x="330" y="110"fontSize="10" fontWeight="bold">{outputPower} kW</tspan></text>
      
    //   <image x="160" y="23" width="90" height="75" href={GridImage} preserveAspectRatio="xMidYMid meet" style={{ imageRendering: 'crisp-edges' }} />
      
    //   <path id="grid-load" d="M 210 100 L 120 146"  stroke="#000" strokeWidth="0.1" fill="transparent" markerEnd="url(#arrowhead)" />
      
    //   <path id="load-grid" d="M 100 170 L 200 100" stroke="#000" strokeWidth="0.1" fill="transparent" markerStart="url(#arrowhead)" />
     
    //   {/* <line x1="210" y1="90" x2="100" y2="160" stroke="#000" strokeWidth="0.1" markerStart="url(#arrowhead)" /> */}
    //   <text x="250" y="28" fontFamily="Arial" fontSize="10">Grid</text>
     
    //   <image x="35" y="110" width="70" height="110" href={LoadImage} />
    //   <path id="path-load" d="M 320 175 Q 220 180 110 180" stroke="#000" strokeWidth="0.1" fill="transparent"/>
    //   <text x="6" y="180" fontFamily="Arial" fontSize="10">Load</text>
     
    //   <g>
    //     <path d="M 0 -10 L -16 0" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
    //     <animateMotion repeatCount="indefinite" dur="5s">
    //       <mpath href="#grid-load" />
    //     </animateMotion>
    //   </g>
    //   <g>
    //     <path d="M 0 -10 L -16 0" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
    //     <animateMotion repeatCount="indefinite" dur="5s">
    //       <mpath href="#load-grid" />
    //     </animateMotion>
    //   </g>
    //   <g>
    //     <path d="M 0 0 L -16 0" stroke="orange" strokeWidth="1.2" markerEnd="url(#arrowhead)" />
    //     <animateMotion repeatCount="indefinite" dur="5s">
    //       <mpath href="#path-load" />
    //     </animateMotion>
    //   </g>

    //   <defs>
    //     <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="10" refY="3.5" orient="auto">
    //       <polygon points="0 0, 10 3.5, 0 7" fill="orange" />
    //     </marker>
    //   </defs>
    // </svg>
  );
};

export default SolarPanelDiagram;
