import React, { useState} from 'react';
import './ProjectSummary.css';
import FinancialIndicators from './FinancialIndicators';
import SystemCosts from './SystemCost';
//  import FinanceImage from '../../../img/photos/FMImage.jpeg';
 



const ProjectSummary = ({yearlyValue,yearToDateValue,selectedYear,selectedPlant}) => {
  const [projectInfo, setProjectInfo] = useState({
    powerGeneration: '',
    solarPanelsCost: '24658',
    invertersCost: '6750',
    smartLoggerCost: '600',
    epcCost: '94633',
    totalSystemCost: '',
   
  });
 

  const [showFinancialTable, setShowFinancialTable] = useState(false);
  const [showProjectProfile, setShowProjectProfile] = useState(false);
  // const[data,setData]=useState(0);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const calculateCosts = () => {
    // Calculation logic for system costs
    const solarPanelsCost = parseFloat(projectInfo.solarPanelsCost) || 0;
    const invertersCost = parseFloat(projectInfo.invertersCost) || 0;
    const smartLoggerCost = parseFloat(projectInfo.smartLoggerCost) || 0;
    const epcCost = parseFloat(projectInfo.epcCost) || 0;

    const totalSystemCost = solarPanelsCost + invertersCost + smartLoggerCost + epcCost;
    setProjectInfo((prevState) => ({
      ...prevState,
      totalSystemCost: totalSystemCost.toFixed(0),
    }));
  };

  const bothTablesVisible = showFinancialTable && showProjectProfile;

  // console.log("yeartodatevalue:",yearToDateValue);
  // console.log("year value:",yearlyValue);
  
  const getDisplayPlantName = (plant) => {
  
    switch (plant) {
        case 'Bodyknits':
            return 'Bodynits';
        case 'Sweelee':
            return 'Swee Lee';
        default:
            return plant;
    } 
};
const displayPlantName = getDisplayPlantName(selectedPlant);
  


  return (
    <div>
      <h3 style={{ color: 'red' }}>TREK RENEWABLE ENERGY SOLUTIONS PTE. LTD.</h3>
      <h4>{displayPlantName} Project</h4>

      <div className="project-summary-container">
      <div className={`left-column ${bothTablesVisible ? 'half-width' : showFinancialTable ? 'full-width' : ''}`}>
        <FinancialIndicators
        selectedPlant={selectedPlant}
          showFinancialTable={showFinancialTable}
          toggleFinancialTableVisibility={() => setShowFinancialTable(!showFinancialTable)}
          projectInfo={projectInfo}
          handleInputChange={handleInputChange}
          yearlyValue={yearlyValue}
          yearToDateValue={yearToDateValue}
          year={selectedYear}
          
        />
          {/* <div>
       {bothTablesVisible && (
        <div>  <img 
        src={FinanceImage} 
        alt="Finance Icon" 
       style={{ width: '920px', height: '510px',float:"left"}}
      /> </div>
      
      )}
      </div> */}
       </div>
       
       <div className={`right-column ${bothTablesVisible ? 'half-width' : showProjectProfile ? 'full-width' : ''}`}>
          <SystemCosts
          selectedPlant={selectedPlant}
           showProjectProfile={showProjectProfile}
           toggleProjectProfile={()=>setShowProjectProfile(!showProjectProfile)}
            projectInfo={projectInfo}
            handleInputChange={handleInputChange}
            calculateCosts={calculateCosts}
          />
       </div>
     
      </div>
     
    </div>
  );
};

export default ProjectSummary;
