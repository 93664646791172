// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 
  .vertical-line {
    border-left: 1px solid #df1818; 
    height: 100%; 
    margin: 0 10px; 
    text-align: left;
    padding-left: 20px;
  }
  .row{
     margin-left: 8px;
     font-weight: bold;
  }
  
  .demo {
    width: 60%;
    height: 100%;
  }
  
  
 .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    margin-left: 120px;
    margin-top: 18px;
    width: 60%;
   
    overflow: auto;
       } 

  .modal-content{
        height:90%
       } 
          
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Overview/Map/PlantModal.css"],"names":[],"mappings":";;EAEE;IACE,8BAA8B;IAC9B,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,kBAAkB;EACpB;EACA;KACG,gBAAgB;KAChB,iBAAiB;EACpB;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;;CAGD;IACG,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;;IAEV,cAAc;OACX;;EAEL;QACM;OACD;;EAEL;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB;;EAEA;;IAEE,YAAY;IACZ,qBAAqB;IACrB,eAAe;EACjB","sourcesContent":["\r\n \r\n  .vertical-line {\r\n    border-left: 1px solid #df1818; \r\n    height: 100%; \r\n    margin: 0 10px; \r\n    text-align: left;\r\n    padding-left: 20px;\r\n  }\r\n  .row{\r\n     margin-left: 8px;\r\n     font-weight: bold;\r\n  }\r\n  \r\n  .demo {\r\n    width: 60%;\r\n    height: 100%;\r\n  }\r\n  \r\n  \r\n .modal {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: fixed;\r\n    z-index: 999;\r\n    margin-left: 120px;\r\n    margin-top: 18px;\r\n    width: 60%;\r\n   \r\n    overflow: auto;\r\n       } \r\n\r\n  .modal-content{\r\n        height:90%\r\n       } \r\n          \r\n  .close {\r\n    color: #aaa;\r\n    float: right;\r\n    font-size: 28px;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .close:hover,\r\n  .close:focus {\r\n    color: black;\r\n    text-decoration: none;\r\n    cursor: pointer;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
