import React from 'react';

const DatePicker2 = ({ viewOption, currentDate, handleDateChange }) => {
    return (
        <div className="date-picker-container">
            <input
                type={viewOption === 'Monthly' ? 'monthly' : viewOption === 'Yearly' ? 'number' : 'date'}
                id="date-picker"
                value={currentDate}
                onChange={handleDateChange}
                className="styled-date-picker"
                pattern={viewOption === 'Yearly' ? '\\d{4}' : viewOption === 'Monthly' ? '\\d{4}-\\d{2}' : '\\d{4}-\\d{2}-\\d{2}'}
                min={viewOption === 'Yearly' ? '1900' : undefined}
                max={viewOption === 'Yearly' ? '2100' : undefined}
            />
        </div>
    );
};

export default DatePicker2;
