
// import React, { useEffect, useState, useCallback } from 'react';
// import axios from 'axios';
// import './OverviewStyle.css';
// //import './LayoutOverview.css';

// const YieldOverview = ({ plantName, currentDate }) => {
//     const [data, setData] = useState({
//         yieldToday: 0,
//         yieldThisMonth: 0,
//         yieldThisYear: 0,
//         totalYield: 0,
//     });

//     const fetchYieldData = useCallback(async () => {
//         try {
//             const response = await axios.post('/v1/overview/station-real-kpi/', {
//                 plant: plantName,
//                 timeZone: currentDate,
//             });

        
//             if (response.data.success && response.data.data) {
//                 setData({
//                     yieldToday: response.data.data.dailyEnergy || 0,
//                     yieldThisMonth: response.data.data.monthEnergy || 0,
//                     yieldThisYear: response.data.data.yearEnergy || 0,
//                     totalYield: response.data.data.cumulativeEnergy || 0,
//                 });
//             } else {
            
//                 setData({
//                     yieldToday: 0,
//                     yieldThisMonth: 0,
//                     yieldThisYear: 0,
//                     totalYield: 0,
//                 });
//             }
//         } catch (error) {
//             console.error("There was an error fetching the data!", error);
            
//             setData({
//                 yieldToday: 0,
//                 yieldThisMonth: 0,
//                 yieldThisYear: 0,
//                 totalYield: 0,
//             });
//         }
//     }, [plantName, currentDate]);

//     useEffect(() => {
//         // Reset data on plant change
//         setData({
//             yieldToday: 0,
//             yieldThisMonth: 0,
//             yieldThisYear: 0,
//             totalYield: 0,
//         });

//         fetchYieldData();
//         const intervalId = setInterval(fetchYieldData, 300000);
//         return () => clearInterval(intervalId);
//     }, [fetchYieldData, plantName]); 

//     return (
//         <div className="card-container">
//             <div className="item">
//                 <label style={{color: "#3b7ddd"}}>Yield Today</label>
//                 <label style={{fontWeight: "bold"}}>{data.yieldToday} kWh</label>
//             </div>

//             <div className="item">
//                 <label style={{color: "#fcb92c"}}>Yield This Month</label>
//                 <label style={{fontWeight: "bold"}}>{data.yieldThisMonth} MWh</label>
//             </div>

//             <div className="item">
//                 <label style={{color: "#dc3545"}}>Yield This Year</label>
//                 <label style={{fontWeight: "bold"}}>{data.yieldThisYear} MWh</label>
//             </div>

//             <div className="item">
//                 <label style={{color: "#1cbb8c", display:"block"}}>Total Yield</label>
//                 <label style={{ fontWeight: "bold", display:"block"}}>{data.totalYield} MWh</label>
//             </div>
//         </div>
//     );
// };

// export default YieldOverview;



import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
// import './OverviewStyle.css';
import './LayoutOverview.css';

const YieldOverview = ({ plantName, currentDate }) => {
    const [data, setData] = useState({
        yieldToday: 0,
        yieldThisMonth: 0,
        yieldThisYear: 0,
        totalYield: 0,
    });

    const fetchYieldData = useCallback(async () => {
        try {
            const response = await axios.post('/v1/overview/station-real-kpi/', {
                plant: plantName,
                timeZone: currentDate,
            });

        
            if (response.data.success && response.data.data) {
                setData({
                    yieldToday: response.data.data.dailyEnergy || 0,
                    yieldThisMonth: response.data.data.monthEnergy || 0,
                    yieldThisYear: response.data.data.yearEnergy || 0,
                    totalYield: response.data.data.cumulativeEnergy || 0,
                });
            } else {
            
                setData({
                    yieldToday: 0,
                    yieldThisMonth: 0,
                    yieldThisYear: 0,
                    totalYield: 0,
                });
            }
        } catch (error) {
            console.error("There was an error fetching the data!", error);
            
            setData({
                yieldToday: 0,
                yieldThisMonth: 0,
                yieldThisYear: 0,
                totalYield: 0,
            });
        }
    }, [plantName, currentDate]);

    useEffect(() => {
        // Reset data on plant change
        setData({
            yieldToday: 0,
            yieldThisMonth: 0,
            yieldThisYear: 0,
            totalYield: 0,
        });

        fetchYieldData();
        const intervalId = setInterval(fetchYieldData, 300000);
        return () => clearInterval(intervalId);
    }, [fetchYieldData, plantName]); 

    return (
        <div className="card-container">
            <div className="item1">
                <label style={{fontSize:"14px"}}>Daily Yield </label> <br/>
                <label style={{fontWeight: "bold"}}>{data.yieldToday} kWh</label>
            </div>

            <div className="item2">
                <label style={{fontSize:"14px"}}>Monthly Yield</label> <br/>
                <label style={{fontWeight: "bold"}}>{data.yieldThisMonth} MWh</label>
            </div>

            <div className="item3">
                <label style={{fontSize:"14px"}}>Yearly Yield</label> <br/>
                <label style={{fontWeight: "bold"}}>{data.yieldThisYear} MWh</label>
            </div>

            <div className="item4">
                <label style={{fontSize:"14px", display:"block"}}>Year To Date Yield</label> 
                <label style={{ fontWeight: "bold", display:"block"}}>{data.totalYield} MWh</label>
            </div>
        </div>
    );
};

export default YieldOverview;
