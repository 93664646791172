import React,{useEffect,useCallback,useState} from 'react';

import { Tooltip } from 'react-tooltip';
import './ProjectSummary.css';

import axios from 'axios';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaChartLine} from "react-icons/fa";
import { HiSignal } from "react-icons/hi2";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {  faBroadcastTower } from '@fortawesome/free-solid-svg-icons';




const FinancialIndicators = ({ selectedPlant,showFinancialTable, year,toggleFinancialTableVisibility, projectInfo, handleInputChange,yearlyValue,yearToDateValue }) => {
  
const [yieldData, setYieldData] = useState({
  yieldToday: 0,
  yieldThisMonth: 0,
  yieldThisYear: 0,
  totalYield: 0,
});


const [revenueData, setRevenueData] = useState({
  consumedAmount: 0,
  exportedAmount: 0,
  administrativeFeesAmount: 0,
  totalRevenueAmount: 0
});

const investmentData = {
  Sweelee: {
    initialInvestment: 183613.10,
    actualInvestment: 182613.10,
  },
  Bodyknits: {
    initialInvestment: 190178.85,
    actualInvestment:  138220.45,
  },
};

const plantData = {
  Sweelee: {
    tenor: 25,
    irr: { lastMonth: "9.17%", current: "9.50%" },
    paybackPeriod: { lastMonth: "9.59", current: "9.30" },
    cashflow: { lastMonth: "(159,721.30)", current: "(157,383.79)" },
  },
  Bodyknits: {
    tenor: 20,
    irr: { lastMonth: "11.35%", current: "11.43%" },
    paybackPeriod: { lastMonth: " 7.66", current: " 7.61" },
    cashflow: { lastMonth: " (120,518.95)", current: " (122,785.55)" },
  },
};
// const { tenor, irr, paybackPeriod, cashflow } = plantData[selectedPlant];


const [showInitialInvestment, setShowInitialInvestment] = useState(false);

  const toggleVisibility = () => {
    setShowInitialInvestment((prev) => !prev);
  };



const thStyle = {
  border: '2px solid #bbb',
  padding: '8px',
  textAlign: 'center',
  fontWeight: 'bold',
  width:'90px',
};
const glitterAnimationStyle = `
  @keyframes glitter {
    0% { background-position: 0 0; }
    50% { background-position: 100% 0; }
    100% { background-position: 0 0; }
  }

  .glitterRow th {
    // background: linear-gradient(90deg, #32CD32, #7FFF00, #32CD32);
     background: linear-gradient(90deg, #32CD32, #7FF000, #32CD32);
    background-size: 200% 100%;
    animation: glitter 3s infinite;
  
    
  }
    
  .glitterRow  {
    // background: linear-gradient(90deg, #32CD32, #7FFF00, #32CD32);
     background: linear-gradient(90deg, #32CD32, #7FF000, #32CD32);
    background-size: 200% 100%;
    animation: glitter 3s infinite;
    
  }
`;

const GlitterEffectStyles = () => (
  <style>
    {glitterAnimationStyle}
  </style>
);

const tdStyle = {
  border: '2px solid #bbb',
  padding: '8px',
  textAlign: 'left',
  fontWeight:"bold",

};
const tdActual = {
  border: '2px solid #bbb',
  padding: '8px',
  textAlign: 'center',
  fontWeight:"bold",

};

const tdActuals = {
  border: '2px solid #bbb',
  padding: '8px',
  textAlign: 'center',
  fontWeight:"bold",
  color:"blue",
  fontSize:"16px"
};
const tdActualBlink = {
  border: '2px solid #bbb',
  padding: '8px',
  textAlign: 'center',
  // backgroundColor:'rgb(212, 243, 243)',
  color:"green",
  fontWeight:"bold",
  fontSize:"16px"
};

const iconStyle = { marginLeft: "8px", color: "blue" };

const currentDate = new Date().toISOString().split('T')[0];
console.log("selectedPlant:",selectedPlant);
const fetchYieldData = useCallback(async () => {
    try {
        const response = await axios.post('/v1/overview/station-real-kpi/', {
            plant: selectedPlant,
            timeZone: currentDate,
        });
      
      
        if (response.data.success && response.data.data) {
            setYieldData({
                yieldToday: response.data.data.dailyEnergy || 0,
                yieldThisMonth: response.data.data.monthEnergy || 0,
                yieldThisYear: response.data.data.yearEnergy || 0,
                totalYield: response.data.data.cumulativeEnergy || 0,
            });
         
        } else {
            setYieldData({
                yieldToday: 0,
                yieldThisMonth: 0,
                yieldThisYear: 0,
                totalYield: 0,
            });
        }
    } catch (error) {
        console.error("There was an error fetching the data!", error);
        setYieldData({
            yieldToday: 0,
            yieldThisMonth: 0,
            yieldThisYear: 0,
            totalYield: 0,
        });
    }
}, [selectedPlant, currentDate]);


//------------------actual------------------
const aggregateYearlyData = (parsedRevenueData, startMonth, endMonth, year) => {
  let consumedTotal = 0;
  let exportedTotal = 0;
  let administrativeTotal = 0;
  let revenueTotal = 0;
  const startDate = new Date(`01 ${startMonth}`);
  const endDate = new Date(`01 ${endMonth}`);


  for (let month of Object.keys(parsedRevenueData)) {
    const monthDate = new Date(`01 ${month}`);

    if (monthDate >= startDate && monthDate <= endDate && month.endsWith(year)) {
      const monthData = parsedRevenueData[month];

      consumedTotal += monthData["Consumed Generation"]?.Amount || 0;
      exportedTotal += monthData["Exported Generation"]?.Amount || 0;
      administrativeTotal += monthData["Administrative Fees"]?.Amount || 0;
      revenueTotal += monthData["Total Revenue"]?.Amount || 0;
    }
  }

  return {
    consumedTotal,
    exportedTotal,
    administrativeTotal,
    revenueTotal,
  };
};
const fetchRevenueData = useCallback(async () => {
  try {
    const response = await axios.post('/fmrequest', {
      plant: selectedPlant,
    });

    if (Array.isArray(response.data) && response.data.length > 0) {
      const revenueData = response.data[0].Revenue;
      // const cashflowData=response.data[0].Cashflow;
      // console.log("cashflowdata:",cashflowData);

      if (revenueData) {
        const parsedRevenueData = JSON.parse(revenueData);
        const lastMonthKey = Object.keys(parsedRevenueData).pop();
        // console.log("lastmonthkey,",lastMonthKey); 
        //  console.log("revenue data:",parsedRevenueData);
        const octoberData = parsedRevenueData[lastMonthKey] || {}; 
        const consumedAmount = octoberData["Consumed Generation"]?.Amount || 0;
        const exportedAmount = octoberData["Exported Generation"]?.Amount || 0;
        const administrativeFeesAmount = octoberData["Administrative Fees"]?.Amount || 0;
        const totalRevenueAmount = octoberData["Total Revenue"]?.Amount || 0;

        const year2023Data = aggregateYearlyData(parsedRevenueData, "Sep-23", "Dec-23", "-23");
        const year2024Data = aggregateYearlyData(parsedRevenueData, "Jan-24", lastMonthKey, "-24");
        const yearToDateData = {
          consumedTotal: year2023Data.consumedTotal + year2024Data.consumedTotal || 0,
          exportedTotal: year2023Data.exportedTotal + year2024Data.exportedTotal || 0,
          administrativeTotal: year2023Data.administrativeTotal + year2024Data.administrativeTotal || 0,
          revenueTotal: year2023Data.revenueTotal + year2024Data.revenueTotal || 0,
        };

        setRevenueData({
          lastMonthKey,
          consumedAmount,
          exportedAmount,
          administrativeFeesAmount,
          totalRevenueAmount,
          year2023: {
            consumedTotal: year2023Data.consumedTotal.toFixed(2),
            exportedTotal: year2023Data.exportedTotal.toFixed(2),
            administrativeTotal: year2023Data.administrativeTotal.toFixed(2),
            revenueTotal: year2023Data.revenueTotal.toFixed(2),
          },
          year2024: {
            consumedTotal: year2024Data.consumedTotal.toFixed(2),
            exportedTotal: year2024Data.exportedTotal.toFixed(2),
            administrativeTotal: year2024Data.administrativeTotal,
            revenueTotal: year2024Data.revenueTotal.toFixed(2),
          },
          yearToDate: {
            consumedTotal: yearToDateData.consumedTotal.toFixed(2),
            exportedTotal: yearToDateData.exportedTotal.toFixed(2),
            administrativeTotal: yearToDateData.administrativeTotal,
            revenueTotal: yearToDateData.revenueTotal.toFixed(2),
          },
        });

      } else {
      
        setRevenueData({
          consumedAmount: 0,
          exportedAmount: 0,
          administrativeFeesAmount: 0,
          totalRevenueAmount: 0,
          year2023: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
          year2024: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
          yearToDate: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
        });
        console.error("No Revenue data found for the selected plant");
      }
    } else {
    
      setRevenueData({
        consumedAmount: 0,
        exportedAmount: 0,
        administrativeFeesAmount: 0,
        totalRevenueAmount: 0,
        year2023: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
        year2024: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
        yearToDate: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
      });
      console.error("Unsupported plant or no data found for the selected plant");
    }
  } catch (error) {
    console.error("There was an error fetching the data!", error);
  
    setRevenueData({
      consumedAmount: 0,
      exportedAmount: 0,
      administrativeFeesAmount: 0,
      totalRevenueAmount: 0,
      year2023: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
      year2024: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
      yearToDate: { consumedTotal: "0.00", exportedTotal: "0.00", administrativeTotal: "0.00", revenueTotal: "0.00" },
    });
  }
}, [selectedPlant]);

useEffect(() => {

    fetchYieldData();
    fetchRevenueData();
    const intervalId = setInterval(fetchYieldData, 300000);
        return () => clearInterval(intervalId);
}, [fetchYieldData,fetchRevenueData]);
const { consumedAmount, exportedAmount, administrativeFeesAmount, totalRevenueAmount,lastMonthKey } = revenueData;
console.log("lastmonthkey,",lastMonthKey);
// 
const multiplier = selectedPlant === "Sweelee" ? 0.13 : selectedPlant === "Bodyknits" ? 0.12 : 0; 

const calculatedValues = {
  monthMultiplied: Number((yieldData.yieldThisMonth * 1000 * multiplier).toFixed(2)),
  yearMultiplied: Number((yieldData.yieldThisYear * 1000 * multiplier).toFixed(2)),
  totalMultiplied: Number((yieldData.totalYield * 1000 * multiplier).toFixed(2)),
};


  return (
    <div>
      <div>
      <div>
      {showFinancialTable ? (
        <button
        style={{
          fontSize: '16px',
          color: 'white',
          backgroundColor: 'green',  
          border: 'none',
          padding: '5px 15px',
          cursor: 'pointer',
          borderRadius: '5px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={toggleFinancialTableVisibility}
      >
        Close
      </button>
      ) : (
        <>    
      <span
     style={{
    fontSize: '20px',
    cursor: 'pointer',
    float: "left",
    marginLeft: '10px',
  }}
  onClick={toggleFinancialTableVisibility}
>

</span>

<button
  data-tooltip-id="plus-tooltip"
  data-tooltip-content="Click to view the table"
  style={{
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'green',
    border: 'none',
    padding: '5px 15px',
    cursor: 'pointer',
    float: 'left',
    marginLeft: '18px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  onClick={toggleFinancialTableVisibility}
>
  View 
</button>

      <Tooltip id="plus-tooltip" place="top" effect="solid" />
          <span style={{ fontSize: '20px', cursor: 'pointer',float:"left", marginLeft: '10px',alignItems: 'center',
          justifyContent: 'center' }} onClick={toggleFinancialTableVisibility}>
            Financial Indicators
          </span>
        </>
      )}
</div>
      {showFinancialTable && (
        <div className="left-column">
          <h4> <FaChartLine style={iconStyle} /> Financial Indicators <FaChartLine style={iconStyle} /></h4>
          <GlitterEffectStyles />
          <table>

            <thead>
          <tr >
          <th rowSpan="3" style={thStyle}> </th>
            <th rowSpan="3" style={thStyle}> </th>
            <th rowSpan="3" style={thStyle}>Projected Cost (S$)</th>
            <th rowSpan="3" style={thStyle}>Actual Cost (S$)</th>
           
            </tr>
            <tr>
            <th  style={thStyle}>Monthly (S$)</th>
            <th  style={thStyle}>Yearly (S$) <br/> (2024)</th>
            <th  style={thStyle}>Year to Date (S$) <br/> ({selectedPlant === 'Bodyknits' ? '1 Year' : '2 Years'})</th>
          </tr>
          {/* <tr className="glitterRow">
            <th  style={thStyle}> 
            <HiSignal style={{color:"white",fontSize:"22px"}} /> Live</th>
            <th  style={thStyle}><HiSignal style={{color:"white",fontSize:"22px"}} /> Live</th>
            <th  style={thStyle}><HiSignal style={{color:"white",fontSize:"22px"}} /> Live</th>
          
          </tr> */}
        </thead>
        <tbody>
          <tr>
            <td></td>
            
            <td style={tdStyle}>1.Initial Investment</td>
        

     <td style={tdActual}>
            {showInitialInvestment && investmentData[selectedPlant]
              ? Number(investmentData[selectedPlant].initialInvestment).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""}
            <span onClick={toggleVisibility} style={{ marginLeft: "10px", cursor: "pointer",color:"blue",fontSize:"17px" }}>
              {showInitialInvestment ? <FaEyeSlash /> : <FaEye />}
            </span>
          </td>
          <td style={tdActual}>
            {investmentData[selectedPlant]
              ? Number(investmentData[selectedPlant].actualInvestment).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0"}
          </td>

            <td style={tdStyle}>            
            </td>
            <td style={tdActual}></td>
            <td style={tdStyle}></td>
          </tr>



          <tr>
          <th className="glitterRow" style={thStyle}><HiSignal style={{color:"white",fontSize:"22px"}} />Live</th>
            <td style={tdStyle}>2. Cash Inflow</td>
            <td style={tdStyle}></td>
            <td style={tdActual}></td>


<td style={tdActualBlink}>
  {Number(calculatedValues.monthMultiplied).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</td>
<td style={tdActualBlink}>
  {Number(calculatedValues.yearMultiplied).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</td>
<td style={tdActualBlink}>
  {Number(calculatedValues.totalMultiplied).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</td>

            
          </tr>
          <tr>
            <td rowSpan={5} style={tdStyle}> Billing <br/>({lastMonthKey?lastMonthKey:"null"})</td>
            <td style={tdStyle}>3(a). Exported Revenue </td>
            <td style={tdStyle}></td>
            <td style={tdActual}></td>
            <td style={tdActual}>{exportedAmount ? exportedAmount.toLocaleString() : '0'}</td>
            <td style={tdActual}>{revenueData.year2024.exportedTotal?parseFloat(revenueData.year2024.exportedTotal).toLocaleString() : "0"}</td>
            <td style={tdActual}>{revenueData.yearToDate.exportedTotal ?parseFloat(revenueData.yearToDate.exportedTotal).toLocaleString(): "0"}</td>
            
          </tr>
          <tr>
            <td style={tdStyle}>3(b).Consumed Revenue </td>
            <td style={tdStyle}></td>
            <td style={tdActual}></td>
            <td style={tdActual}>{consumedAmount ? consumedAmount.toLocaleString() : '0'}</td>
            <td style={tdActual}>{revenueData.year2024.consumedTotal?parseFloat(revenueData.year2024.consumedTotal).toLocaleString(): "0"}</td>
            <td style={tdActual}>{revenueData.yearToDate.consumedTotal?parseFloat(revenueData.yearToDate.consumedTotal).toLocaleString(): "0"}</td>
          </tr>


<tr>
  <td style={tdStyle}> 3(c). Administrative Fees</td>
  <td style={tdStyle}></td>
  <td style={tdActual}></td>
  <td style={tdActual}>
    {administrativeFeesAmount 
      ? (administrativeFeesAmount < 0 
          ? `(${Math.abs(administrativeFeesAmount).toFixed(2).toLocaleString()})` 
          : administrativeFeesAmount.toLocaleString()) 
      : '0'}
  </td>
  <td style={tdActual}>
    {revenueData.year2024?.administrativeTotal 
      ? (revenueData.year2024.administrativeTotal < 0 
          ? `(${Math.abs(revenueData.year2024.administrativeTotal).toFixed(2).toLocaleString()})` 
          : parseFloat(revenueData.year2024.administrativeTotal).toFixed(2).toLocaleString()) 
      : "0"}
  </td>
  <td style={tdActual}>
    {revenueData.yearToDate?.administrativeTotal 
      ? (revenueData.yearToDate.administrativeTotal < 0 
          ? `(${Math.abs(revenueData.yearToDate.administrativeTotal).toFixed(2).toLocaleString()})` 
          : parseFloat(revenueData.yearToDate.administrativeTotal).toFixed(2).toLocaleString()) 
      : "0"}
  </td>
</tr>

          <tr>
          
            <td style={tdStyle}>3(d). Actual Revenue</td>
            <td style={tdStyle}></td>
            <td style={tdActual}></td>
            <td style={tdActuals}>{totalRevenueAmount ? totalRevenueAmount.toLocaleString() : '0'}</td>
            <td style={tdActuals}>{parseFloat(revenueData.year2024.revenueTotal).toLocaleString()}</td>
            <td style={tdActuals}>{parseFloat(revenueData.yearToDate.revenueTotal).toLocaleString()}</td>
            
          </tr>


        </tbody>
      </table>
      <Tooltip id="cell-tooltip" place="top" effect="solid" />
      <GlitterEffectStyles />
     <table style={{ width: '100%', borderCollapse: 'collapse' , marginTop:"15px"}}>
    
       <thead>
         <tr>
           <th style={{ padding: '8px', border: '1px solid #bbb', backgroundColor: '#f4f4f4' , textAlign: 'center' }}></th>
           <th style={{ padding: '8px', border: '1px solid #bbb', backgroundColor: '#f4f4f4', textAlign: 'center' }}>Projected</th>
           <th className="glitterRow" style={thStyle}><HiSignal style={{color:"white",fontSize:"22px"}} />Live</th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>Tenor (Years)</td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>{plantData[selectedPlant]?.tenor}</td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>{lastMonthKey?lastMonthKey:"null"}</td>
         </tr>
         <tr>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>IRR</td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>{plantData[selectedPlant]?.irr.current}</td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center' ,fontWeight:"bold"}}>{plantData[selectedPlant]?.irr.lastMonth}</td>
         </tr>
         <tr>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>Payback Period (Years)</td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>{plantData[selectedPlant]?.paybackPeriod.current}</td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>{plantData[selectedPlant]?.paybackPeriod.lastMonth}</td>
         </tr>
         <tr>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>Cumulative Cash (Outflow)/Inflow</td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center' ,fontWeight:"bold"}}>
           {plantData[selectedPlant]?.cashflow.current}
           </td>
           <td style={{ padding: '8px', border: '1px solid #bbb', textAlign: 'center',fontWeight:"bold" }}>{plantData[selectedPlant]?.cashflow.lastMonth}</td>
         </tr>
       </tbody>
     </table>
   </div>

      )}
      </div>
     
   

      </div>
     
  );
  

};
export default FinancialIndicators;
