import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PlantManagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus,faSearch } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';


const PlantManagement = () => {
  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingPlantId, setEditingPlantId] = useState(null);
  const [updatedPlant, setUpdatedPlant] = useState({});
  const [newPlant, setNewPlant] = useState({
    Company: '',
    PlantName: '',
    Address: '',
    GPSLocation: '',
    TotalStringCapacity: '',
    PlantTimeZone: '',
    Country: '',
    EmailAddress: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [searchQuery, setSearchQuery] = useState('');

  // Fetch plant data
  useEffect(() => {
    const fetchPlantInfo = async () => {
      try {
        const response = await axios.post('/plant/info');
        setPlants(response.data);
      } catch (error) {
        setError('Failed to fetch plant information.');
      } finally {
        setLoading(false);
      }
    };
    fetchPlantInfo();
  }, []);

  // Handle Edit
  const handleEdit = (plantId) => {
    setEditingPlantId(plantId);
    const plant = plants.find((p) => p.Id === plantId);
    setUpdatedPlant(plant);
  };

  // Handle Save Edit
  const handleSave = async () => {
    try {
      await axios.put(`/plant/update/${editingPlantId}`, updatedPlant);
      setPlants((prevPlants) =>
        prevPlants.map((plant) =>
          plant.Id === editingPlantId ? updatedPlant : plant
        )
      );
      setEditingPlantId(null);
    } catch (error) {
      console.error('Failed to update plant:', error.message);
    }
  };

  // Handle Delete
  const handleDelete = async (plantId) => {
    if (window.confirm('Are you sure you want to delete this plant?')) {
      try {
        await axios.delete(`/plant/delete/${plantId}`);
        setPlants(plants.filter((plant) => plant.Id !== plantId));
      } catch (error) {
        console.error('Failed to delete plant:', error.message);
      }
    }
  };

  // Handle Create Plant
  const handleCreatePlant = async () => {
    try {
       await axios.post('/plant/create', newPlant);
      setSuccessMessage('Plant created successfully!');
      const updatedPlantInfoResponse = await axios.post('/plant/info');
      setPlants(updatedPlantInfoResponse.data);

      setNewPlant({
        Company: '',
        PlantName: '',
        Address: '',
        GPSLocation: '',
        TotalStringCapacity: '',
        PlantTimeZone: '',
        Country: '',
        EmailAddress: '',
      });
      setTimeout(() => {
        setSuccessMessage('');
        setIsModalOpen(false);
      }, 3000);


    } catch (error) {
      console.error('Failed to add plant:', error.message);
    }
  };

  // Handle Input Change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editingPlantId) {
      setUpdatedPlant({ ...updatedPlant, [name]: value });
    } else {
      setNewPlant({ ...newPlant, [name]: value });
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const filteredPlants = plants.filter((plant) =>
    plant.PlantName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="plant-management">
      <h3 className='plant-head'>Plant Management</h3>
  
      <div className="plant-container">
     <div className="search-bar">
 
    <input
      type="text"
      placeholder="Search by plant name"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
     <span className="search-icon">
      <FontAwesomeIcon icon={faSearch} />
    </span>
  </div>

  <button className="plant-create" onClick={handleOpenModal}>
    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px' }} />
    Add Plant
  </button>
</div>

     
      {successMessage && <div className="success-message">{successMessage}</div>}
      <table className="plant-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Company</th>
            <th>Plant Name</th>
           
            <th>Address</th>
            <th>String Capacity</th>
            <th>Country</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {filteredPlants.length > 0 ? (
          filteredPlants.map((plant,index) => (
            <tr key={plant.Id}>
              <td>{index + 1}</td>
            
              <td>
        {editingPlantId === plant.Id ? (
          <input
            type="text"
            name="Company"
            value={updatedPlant.Company}
            onChange={handleInputChange}
          />
        ) : (
          plant.Company
        )}
      </td>
              <td>
                {editingPlantId === plant.Id ? (
                  <input
                    type="text"
                    name="PlantName"
                    value={updatedPlant.PlantName}
                    onChange={handleInputChange}
                  />
                ) : (
                  plant.PlantName
                )}
              </td>
              <td>
        {editingPlantId === plant.Id ? (
          <input
            type="text"
            name="Address"
            value={updatedPlant.Address}
            onChange={handleInputChange}
          />
        ) : (
          plant.Address
        )}
      </td>
              {/* <td>{plant.TotalStringCapacity} kWh</td> */}
              <td>
        {editingPlantId === plant.Id ? (
          <input
            type="number"
            name="TotalStringCapacity"
            value={updatedPlant.TotalStringCapacity}
            onChange={handleInputChange}
          />
        ) : (
          `${plant.TotalStringCapacity}`
        )}
      </td>
              {/* <td>{plant.Country}</td> */}
              <td>
        {editingPlantId === plant.Id ? (
          <input
            type="text"
            name="Country"
            value={updatedPlant.Country}
            onChange={handleInputChange}
          />
        ) : (
          plant.Country
        )}
      </td>

              {/* <td>{plant.EmailAddress}</td> */}
              <td>
        {editingPlantId === plant.Id ? (
          <input
            type="email"
            name="EmailAddress"
            value={updatedPlant.EmailAddress}
            onChange={handleInputChange}
          />
        ) : (
          plant.EmailAddress
        )}
      </td>
              <td className='update-buttons'>
                {editingPlantId === plant.Id ? (
                  <>
                    <button className="plant-save"onClick={handleSave}>Save</button>
                    <button className="plant-update-cancel" onClick={() => setEditingPlantId(null)}>Cancel</button>
                  </>
                ) : (
                  <>
                  <Tooltip id="edit-tooltip" place="top" effect="solid" />
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => handleEdit(plant.Id)}
                      style={{ cursor: 'pointer', marginRight: '15px', color: 'blue',fontSize:"22px" }}
                       data-tooltip-id="edit-tooltip"
                data-tooltip-content="Edit"
                    />
                    <Tooltip id="delete-tooltip" place="top" effect="solid" />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleDelete(plant.Id)}
                      style={{ cursor: 'pointer', color: 'red',fontSize:"22px" }}
                       data-tooltip-id="delete-tooltip"
                data-tooltip-content="Delete"
                    />
                    
                  </>
                )}
              </td>
            </tr>

           ))
                ) : (
                <tr>
                   <td colSpan="100%" style={{ textAlign: "center" }}>
                    No data found for the search criteria
                   </td>
              </tr>
           )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="plant-create-modal">
          <div className="plant-modal-content">
            <h3>Add New Plant</h3>
            <form>
              <div>
                <label>Company:</label>
                <input
                  type="text"
                  name="Company"
                  value={newPlant.Company}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Plant Name:</label>
                <input
                  type="text"
                  name="PlantName"
                  value={newPlant.PlantName}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div>
                <label>Plant Type:</label>
                <input
                  type="text"
                  name="PlantType"
                  value={newPlant.PlantType}
                  onChange={handleInputChange}
                />
              </div> */}
              <div>
                <label>Address:</label>
                <input
                  type="text"
                  name="Address"
                  value={newPlant.Address}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div>
                <label>GPS Location:</label>
                <input
                  type="text"
                  name="GPSLocation"
                  value={newPlant.GPSLocation}
                  onChange={handleInputChange}
                />
              </div> */}
              <div>
                <label>String Capacity:</label>
                <input
                  type="number"
                  name="TotalStringCapacity"
                  value={newPlant.TotalStringCapacity}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div>
                <label>Time Zone:</label>
                <input
                  type="text"
                  name="PlantTimeZone"
                  value={newPlant.PlantTimeZone}
                  onChange={handleInputChange}
                />
              </div> */}
              <div>
                <label>Country:</label>
                <input
                  type="text"
                  name="Country"
                  value={newPlant.Country}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="EmailAddress"
                  value={newPlant.EmailAddress}
                  onChange={handleInputChange}
                />
              </div>
              <button type="button" style={{ backgroundColor: "#4CAF50"}}onClick={handleCreatePlant}>
                Add Plant
              </button>
              <button type="button" style={{backgroundColor:"#535553"}} onClick={handleCloseModal}>
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlantManagement;
