
// import React, { useEffect, useState } from 'react';
// import ReactECharts from 'echarts-for-react';
// import axios from 'axios';
// import '../OverviewStyle.css';

// const LineChart = ({ apiUrl, date, plant }) => {
//   const [chartData, setChartData] = useState({ xAxis: [], irradianceSeries: [], powerOutSeries: [] });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.post(apiUrl, { Date: date, plant });
//         const { xAxis, 'Irradiance W/m2': irradianceData, 'PowerOut kW': powerOutData } = response.data.data;

//         setChartData({
//           xAxis: xAxis.length ? xAxis : ['No Data'], 
//           irradianceSeries: irradianceData.length ? irradianceData : [0], 
//           powerOutSeries: powerOutData.length ? powerOutData : [0], 
//         });
//       } catch (error) {
//         console.error('Error fetching data:', error);
        
//         setChartData({
//           xAxis: ['No Data'], 
//           irradianceSeries: [0], 
//           powerOutSeries: [0],
//         });
//       }
//     };

//     fetchData();
//     const intervalId = setInterval(fetchData, 300000); 
//         return () => clearInterval(intervalId);

//   }, [apiUrl, date, plant]);


//   //font size
//   const getFontSize = () => {
//     const width = window.innerWidth;
//      if(width>=1403 && width <= 1540) return 14;
//      if(width>=1541 && width <= 1643) return 16;
//     if (width >= 1644 && width <= 1852) return 18; 
//     if (width >= 1853 && width <= 2529) return 20; 
//     if (width >= 2530 && width <= 3795) return 24; 
//     if (width >= 3796 && width <= 5060) return 35; 
//     if (width >= 5061) return 44; 
//     return 12; 
//   };  

//   const getOption = () => ({
//     color: ['#000000', '#ff0000'],
//     backgroundColor: 'white',
//     grid: {
//       left: '8%',
//       right: '6%',
//       bottom: '3%',
//       top: '22%',
//       containLabel: true,
      
//     },
//     tooltip: {
//       trigger: 'axis',
//       axisPointer: {
//         type: 'cross',
//         label: {
//           backgroundColor: '#6a7985',
//           fontSize:getFontSize(),
//         },
//       },
//       textStyle: {
//         fontSize: getFontSize(),
//         color: 'black', 
//       },
//     },
//     toolbox: {
//       feature: {
//         saveAsImage: {
//           title: 'Save As Image', 
//           iconStyle: {
//             fontSize: getFontSize(),  
//           },
         
//         },
       
//       },
//     },
//     legend: {
//       data: ['Irradiance W/m²', 'PowerOut kW'],
//       textStyle: {
//         color: 'black',
//         fontSize:getFontSize(),
//       },
//     },
//     xAxis: {
//       type: 'category',
//       boundaryGap: false,
//       axisLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.2)',
          
//         },
//       },
//       splitLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.1)',
//         },
//       },
      
//       axisLabel: {
//         color: 'black',
//         fontSize:getFontSize(),
//         rotate: 1,
//         margin: 15,
//       },
//       data: chartData.xAxis,
      
//     },
//     yAxis: [
//       {
//         type: 'value',
//         name: 'PowerOut kW',
//         nameLocation: 'end',
//         nameGap: 15,
//         nameTextStyle: {
//           color: 'black',
//           fontSize: getFontSize(),
//           fontWeight: 'bold',
//           align: 'center',
//         },
//         position: 'left',
//         axisLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.2)',
//           },
//         },
//         splitLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.1)',
//           },
//         },
//         axisLabel: {
//           color: 'black',
//           fontSize:getFontSize(),
//         },
//       },
//       {
//         type: 'value',
//         name: 'Irradiance W/m²',
//         nameLocation: 'end',
//         nameGap: 15,
//         nameTextStyle: {
//           color: 'black',
//           fontSize:getFontSize(),
//           fontWeight: 'bold',
//           align: 'center',
//         },
//         position: 'right',
//         axisLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.2)',
//           },
//         },
//         splitLine: {
//           lineStyle: {
//             color: 'rgba(255,255,255,.1)',
//           },
//         },
//         axisLabel: {
//           color: 'black',
//           fontSize:getFontSize(),
//         },
//       },
//     ],
//     series: [
//       {
//         name: 'Irradiance W/m²',
//         type: 'line',
//         areaStyle: { color: 'rgba(0, 0, 0, 0.1)' },
//         data: chartData.irradianceSeries,
//         yAxisIndex: 1,
//       },
//       {
//         name: 'PowerOut kW',
//         type: 'line',
//         areaStyle: { color: 'rgba(255, 0, 0, 0.3)' },
//         data: chartData.powerOutSeries,
//         yAxisIndex: 0,
//       },
//     ],
//   });

//   const getDynamicHeight = () => {
//     const width = window.innerWidth;
//     console.log("width:",width);
    
//     if (width >= 1644 && width <= 1852) return '350px';
//     if (width >= 1853 && width <= 2529) return '450px';
//     if (width >= 2530 && width <= 3795) return '500px';
//     if (width >= 3796 && width <= 5060) return '740px';
//     if (width >= 5061) return '900px';
//     return '300px';
//   };
  
//   return <ReactECharts option={getOption()} className='day-graph1' style={{height: getDynamicHeight()}}/>;
// };

// export default LineChart;

import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import '../OverviewStyle.css';

const LineChart = ({ apiUrl, date, plant }) => {
  const [chartData, setChartData] = useState({ xAxis: [], irradianceSeries: [], powerOutSeries: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(apiUrl, { Date: date, plant });
        const { xAxis, 'Irradiance W/m2': irradianceData, 'PowerOut kW': powerOutData } = response.data.data;

        setChartData({
          xAxis: xAxis.length ? xAxis : ['No Data'], 
          irradianceSeries: irradianceData.length ? irradianceData : [0], 
          powerOutSeries: powerOutData.length ? powerOutData : [0], 
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        
        setChartData({
          xAxis: ['No Data'], 
          irradianceSeries: [0], 
          powerOutSeries: [0],
        });
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 300000); 
        return () => clearInterval(intervalId);

  }, [apiUrl, date, plant]);


  //font size
  const getFontSize = () => {
    const width = window.innerWidth;
     if(width>=1403 && width <= 1540) return 14;
     if(width>=1541 && width <= 1643) return 16;
    if (width >= 1644 && width <= 1852) return 18; 
    if (width >= 1853 && width <= 2529) return 20; 
    if (width >= 2530 && width <= 3795) return 24; 
    if (width >= 3796 && width <= 5060) return 35; 
    if (width >= 5061) return 44; 
    return 12; 
  };  

  const getOption = () => ({
    color: ['#000000', '#ff0000'],
    backgroundColor: 'white',
    grid: {
      left: '5%',
      right: '7%',
      bottom: '1%',
      top: '25%',
      containLabel: true,
      
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
          fontSize:getFontSize(),
        },
      },
      textStyle: {
        fontSize: getFontSize(),
        color: 'black', 
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: 'Save As Image', 
          iconStyle: {
            fontSize: getFontSize(),  
          },
         
        },
       
      },
    },
    legend: {
      data: ['Irradiance W/m²', 'PowerOut kW'],
      textStyle: {
        color: 'black',
        fontSize:getFontSize(),
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.2)',
          
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
        },
      },
      
      axisLabel: {
        color: 'black',
        fontSize:getFontSize(),
        rotate: 1,
        margin: 15,
      
      },
      data: chartData.xAxis,
      
    },
    yAxis: [
      {
        type: 'value',
        name: 'PowerOut kW',
        nameLocation: 'end',
        nameGap: 15,
        nameTextStyle: {
          color: 'black',
          fontSize: getFontSize(),
          fontWeight: 'bold',
          align: 'center',
        },
        position: 'left',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.2)',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)',
          },
        },
        axisLabel: {
          color: 'black',
          fontSize:getFontSize(),
        },
      },
      {
        type: 'value',
        name: 'Irradiance W/m²',
        nameLocation: 'end',
        nameGap: 15,
        nameTextStyle: {
          color: 'black',
          fontSize:getFontSize(),
          fontWeight: 'bold',
          align: 'center',
        },
        position: 'right',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.2)',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.1)',
          },
        },
        axisLabel: {
          color: 'black',
          fontSize:getFontSize(),
        },
      },
    ],
    series: [
      {
        name: 'Irradiance W/m²',
        type: 'line',
        areaStyle: { color: 'rgba(0, 0, 0, 0.1)' },
        data: chartData.irradianceSeries,
        yAxisIndex: 1,
      },
      {
        name: 'PowerOut kW',
        type: 'line',
        areaStyle: { color: 'rgba(255, 0, 0, 0.3)' },
        data: chartData.powerOutSeries,
        yAxisIndex: 0,
      },
    ],
  });

  const getDynamicHeight = () => {
    const width = window.innerWidth;
    console.log("width:",width);

    if (width >= 1403 && width <= 1540) return '240px';
    if (width >= 1541 && width <= 1643) return '275px';
    if (width >= 1644 && width <= 1852) return '300px';
    if (width >= 1853 && width <= 2529) return '350px';
    if (width >= 2530 && width <= 3795) return '500px';
    if (width >= 3796 && width <= 5060) return '740px';
    if (width >= 5061) return '900px';
    return '210px';
  };
  
  return <ReactECharts option={getOption()} className='day-graph1' style={{height: getDynamicHeight()}}/>;
};

export default LineChart;
