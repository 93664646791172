

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserInfoTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUserPlus,faSearch } from '@fortawesome/free-solid-svg-icons';

const userTypes = {
  1: 'User',
  2: 'Admin',
  3: 'Owner',
  9: 'SuperAdmin',
  '':''
};

const UserInfoTable = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    UserName: '',
    UserType: 1, // Default to 'User'
    cellPhone: '',
    email: '',
    Pwd: '',
    Plant: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.post('/user/info');
        setUserInfo(response.data);
      } catch (error) {
        setError("Failed to fetch user information. Could you please log out and log in again?");
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handleEdit = (userId) => {
    setEditingUserId(userId);
    const user = userInfo.find((user) => user.UserId === userId);
    setUpdatedValues(user);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedValues({ ...updatedValues, [name]: value });
  };

  const handleUserTypeChange = (event) => {
    setUpdatedValues({ ...updatedValues, UserType: parseInt(event.target.value, 10) });
  };

  const handleSave = async () => {
    try {
      await axios.put(`/user/update/${editingUserId}`, updatedValues);
      setUserInfo((prevUserInfo) =>
        prevUserInfo.map((user) =>
          user.UserId === editingUserId ? updatedValues : user
        )
      );
      setEditingUserId(null);
    } catch (error) {
      console.error('Failed to update user:', error.message);
    }
  };

  const handleCancel = () => {
    setEditingUserId(null);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/user/delete/${userId}`);
        setUserInfo(userInfo.filter((user) => user.UserId !== userId));
      } catch (error) {
        console.error('Failed to delete user:', error.message);
      }
    }
  };

  const handleCreateUserChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleCreateUserTypeChange = (event) => {
    setNewUser({ ...newUser, UserType: parseInt(event.target.value, 10) });
  };

  const handleCreateUser = async () => {
    try {
      await axios.post('/user/add', newUser);
      setSuccessMessage('User created successfully!');
      const updatedUserInfoResponse = await axios.post('/user/info');
      setUserInfo(updatedUserInfoResponse.data);

      setNewUser({
        UserName: '',
        UserType: 1, // Default to 'User'
        cellPhone: '',
        email: '',
        Pwd: '',
        Plant: '',
      });
      
      setTimeout(() => {
        setSuccessMessage('');
        setIsModalOpen(false);
      }, 4000);
      
    } catch (error) {
      console.error('Failed to create user:', error.message);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const filteredUser = userInfo.filter((user) =>
    user.UserName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className='header-container'>
      <h2>User Management</h2>

      <div className="user-container">
     <div className="user-search-bar">
 
    <input
      type="text"
      placeholder="Search by user name"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
     <span className="search-icon">
      <FontAwesomeIcon icon={faSearch} />
    </span>
  </div>

      <button className="user-create" onClick={handleOpenModal}>
        <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '8px' }} />
        Create New
      </button>
      </div>

      {successMessage && <div className="success-message">{successMessage}</div>}

      {userInfo.length > 0 ? (
        <table className="user-info-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>User Name</th>
              <th>User Type</th>
              <th>Password</th>
              <th>Plant</th>
              <th>Email</th>
              <th>Cell Phone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {filteredUser.length > 0 ? (
            filteredUser.map((user,index) => (
              <tr key={user.UserId}>
                <td>{index + 1}</td>
                <td>
                  {editingUserId === user.UserId ? (
                    <input
                      type="text"
                      name="UserName"
                      value={updatedValues.UserName}
                      onChange={handleInputChange}
                    />
                  ) : (
                    user.UserName
                  )}
                </td>
                <td>
                  {editingUserId === user.UserId ? (
                    <select
                      name="UserType"
                      value={updatedValues.UserType}
                      onChange={handleUserTypeChange}
                    >
                      {Object.entries(userTypes).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    userTypes[user.UserType]
                  )}
                </td>
                <td>
                  {editingUserId === user.UserId ? (
                    <input
                      type="text"
                      name="Pwd"
                      value={updatedValues.Pwd}
                      onChange={handleInputChange}
                    />
                  ) : (
                    user.Pwd
                  )}
                </td>
                <td>
                  {editingUserId === user.UserId ? (
                    <input
                      type="text"
                      name="Plant"
                      value={updatedValues.Plant}
                      onChange={handleInputChange}
                    />
                  ) : (
                    user.Plant
                  )}
                </td>
                
                <td>
                  {editingUserId === user.UserId ? (
                    <input
                      type="text"
                      name="email"
                      value={updatedValues.email}
                      onChange={handleInputChange}
                    />
                  ) : (
                    user.email
                  )}
                </td>
                <td>
                  {editingUserId === user.UserId ? (
                    <input
                      type="text"
                      name="cellPhone"
                      value={updatedValues.cellPhone}
                      onChange={handleInputChange}
                    />
                  ) : (
                    user.cellPhone
                  )}
                </td>
                
                <td style={{ display: 'flex', gap: '8px' }}>
                  {editingUserId === user.UserId ? (
                    <>
                      <button onClick={handleSave} style={{ margin: '0' }}>Save</button>
                      <button onClick={handleCancel} style={{ margin: '0' }}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ cursor: 'pointer', marginRight: '30px', color: 'blue', fontSize: '22px' }}
                        onClick={() => handleEdit(user.UserId)}
                        title="Edit"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ cursor: 'pointer', color: 'red', fontSize: '22px' }}
                        onClick={() => handleDelete(user.UserId)}
                        title="Delete"
                      />
                    </>
                  )}
                </td>
              </tr>
                  ))
                ) : (
                <tr>
                   <td colSpan="100%" style={{ textAlign: "center" }}>
                    No data found for the search criteria
                   </td>
              </tr>
           )}
            
          </tbody>
        </table>
      ) : (
        <div>No user info available.</div>
      )}

      {isModalOpen && (
        <div className="user-modal">
          <div className="user-modal-content">
            <h3>Create New User</h3>
            <form>
              <div className="user-form-group">
                <label>User Name:</label>
                <input
                  type="text"
                  name="UserName"
                  value={newUser.UserName}
                  onChange={handleCreateUserChange}
                />
              </div>
              <div className="user-form-group">
                <label>User Type:</label>
                <select
                  name="text"
                  value={newUser.UserType}
                  onChange={handleCreateUserTypeChange}
                >
                  {Object.entries(userTypes).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="user-form-group">
                <label>Cell Phone:</label>
                <input
                  type="text"
                  name="cellPhone"
                  value={newUser.cellPhone}
                  onChange={handleCreateUserChange}
                />
              </div>
              <div className="user-form-group">
                <label>Email:</label>
                <input
                  type="text"
                  name="email"
                  value={newUser.email}
                  onChange={handleCreateUserChange}
                />
              </div>
              <div className="user-form-group">
                <label>Password:</label>
                <input
                  type="text"
                  name="Pwd"
                  value={newUser.Pwd}
                  onChange={handleCreateUserChange}
                />
              </div>
              <div className="user-form-group">
                <label>Plant:</label>
                <input
                  type="text"
                  name="Plant"
                  value={newUser.Plant}
                  onChange={handleCreateUserChange}
                />
              </div>
              <div className="user-modal-buttons">
                <button type="button" onClick={handleCreateUser} style={{ margin: '0' }}>Create User</button>
                <button type="button" onClick={handleCloseModal} style={{ margin: '0' }}>Close</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfoTable;
