
import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import PlantModal from './PlantModal'; 
import bodynitsImage from '../../../img/photos/Bodynits.jpg';
import plantAImage from '../../../img/SolarPanel.png';
import sweeleeImage from '../../../img/photos/Sweelee.png';

// Custom marker icon
const markerIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconSize: [25, 41], 
  iconAnchor: [12, 41], 
  popupAnchor: [1, -34], 
});

const locations = {
  Bodyknits: {
    position: [1.331389, 103.9525],
    name: 'Bodyknits - Singapore',
    address: '12 Changi South Lane, Bodynits Building Singapore 486353',
    coordinates: '103° 57 09″ N 1° 19 53″ E',
    capacity: '147.42',
    startDate: '2023-12-22',
    imageSrc: bodynitsImage 
  },
  Sweelee: {
    position: [1.3191072470870138, 103.87584746889154],
    name: 'Swee Lee - Singapore',
    address: '150 Sims Drive Singapore 387381',
    coordinates: '103° 87 58″ N 1° 31 91″ E',
    capacity: '134.865',
    startDate: '2023-08-14',
    imageSrc: sweeleeImage
  }
};

const locationsForUserType4 = {
  Bodyknits: {
    position: [1.37240,103.97148],
    name: 'Plant A - Singapore',
    address: '30 Loyang Way #07-13/14/15, Singapore 508769',
    coordinates: '1° 18 00″ N 103° 56 00″ E',
    capacity: '147.42',
    startDate: '2023-12-22',
    imageSrc: plantAImage 
  },
  Sweelee: {
    position: [1.37240,103.97148] ,
    name: 'Plant B - Singapore',
    address: '30 Loyang Way #07-13/14/15, Singapore 508769',
    coordinates: '1° 18 00″ N 103° 56 00″ E',
    capacity: '134.865',
    startDate: '2023-08-14',
    imageSrc: plantAImage
  }
};

const MapComponent = ({ selectedPlant,userType}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activePlant, setActivePlant] = useState(null);
  const markerRef = useRef(null); 


  // const location = locations[selectedPlant] || locations.Bodyknits;
  // const currentLocations = userType === 4 ? locationsForUserType4 : locations;
  const currentLocations = (userType === 4 || userType === '4') 
  ? locationsForUserType4 
  : (userType === 5 || userType === '5') 
    ? locationsForUserType4 
    : locations;
  const location = currentLocations[selectedPlant] || currentLocations.Bodyknits;
 

  console.log("map usertype:",userType);
  console.log("map selected palnt:",selectedPlant);

  const calculateRunningDays = (startDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const timeDiff = currentDate - start;
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24)); 
  };

  const locationWithRunningDays = {
    ...location,
    runningDays: calculateRunningDays(location.startDate),
  };

  const ChangeView = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(center);
    }, [center, map]);
    return null;
  };

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup(); 
    }
  }, [selectedPlant]);

  
  const handleMarkerClick = (plant) => {
    if (!isModalOpen) { 
      console.log('Marker clicked. Opening modal for:', plant);
      setActivePlant(plant);
      setIsModalOpen(true);
    }
  };
  
  
  const handleCloseModal = () => {
    // console.log('Closing modal');
    setIsModalOpen(false);
    setActivePlant(null);
  };
  
  const getDisplayPlantName = (plant, userType) => {
    if (userType === '4' || userType===4) {
        return 'Plant A';
    } else if (userType === '5' || userType===5) {
        
      switch (plant) {
          case 'Bodyknits':
              return 'Plant A'; 
          case 'Sweelee':
              return 'Plant B';
          default:
              return plant;
      }
  }
    
    switch (plant) {
        case 'Bodyknits':
            return 'Bodynits';
        case 'Sweelee':
            return 'Swee Lee';
        default:
            return plant;
    } 
};
const displayPlantName = getDisplayPlantName(selectedPlant, userType);

  return (
    <div>
      <MapContainer
        center={location.position}
        zoom={25}
        style={{ height: '600px', width: '100%' }}
      >
        <ChangeView center={location.position} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a>"
        />
        <Marker
          position={location.position}
          icon={markerIcon}
          ref={markerRef}
        >
          <Popup>
            <div>
              <button 
                onClick={() => handleMarkerClick(locationWithRunningDays)} 
                style={{
                  backgroundColor: 'green',
                  color: 'white',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}>
                Weather
              </button>
            </div>
          </Popup>
        </Marker>
      </MapContainer>
      {activePlant && (
        <PlantModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          plantDetails={activePlant} 
          selectedPlant={displayPlantName}
        />
      )}
    </div>
  );
};

export default MapComponent;
