// ReportTypeSelector.js
import React from 'react';

const ReportTypeSelector = ({ selectedReportType, setSelectedReportType }) => {
  return (
    <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ textAlign: 'center' }}>
      {['Day', 'Month', 'Year', 'LifeTime'].map((type) => (
        <React.Fragment key={type}>
          <input
            type="radio"
            className="btn-check"
            name="btnGroupRadio1"
            id={`${type}Radio1`}
            value={type}
            autoComplete="off"
            checked={selectedReportType === type}
            onChange={(e) => setSelectedReportType(e.target.value)}
          />
          <label className="btn btn-outline-primary" htmlFor={`${type}Radio1`}>{type}</label>
          <span style={{ marginRight: '10px' }}></span>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ReportTypeSelector;
