import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PlantModal.css'; 

const PlantModal = ({ isOpen, onClose, plantDetails,selectedPlant }) => {
  if (!isOpen) return null;

  const { address, coordinates, capacity, startDate, runningDays,imageSrc } = plantDetails;

  return (
    <div className="modal">
      <div className="modal-content animate">
        <div className="imgcontainer">
          <span onClick={onClose} className="close" title="Close Modal">&times;</span>
          <div className="card card-1" style={{ 
  background: 'linear-gradient(to left, rgba(219, 225, 228, 0.6) 0%, rgba(211, 224, 232, 0) 50%)' 
}}
>
            <div id="demo" className="carousel slide">
            
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                   
                    <div className="col-5 justify-content-left" >
                    <h6 style={{fontSize:'18px'}}>{selectedPlant} -Singapore</h6>
                       <img id="img" className="product-image" style={{ width: '200px', height: '200px',boxShadow: '0 0 0 3px #fff, 0 0 0 3px #000'}} src={imageSrc} alt="Plant" /> 
                    </div>
                    <div className="col-7" style={{textAlign:'left',marginTop:'35px'}}>
                      <h5>Plant Overview</h5>
                       <div className="spec-2" style={{ fontSize: '16px', textAlign:'left' }}>   
                        <span> {address}</span><br />
                        <span><strong>Long & Lat:</strong> {coordinates}</span><br />
                        <span><strong>Total string capacity:</strong> {capacity} kWp</span><br />
                        <span><strong>Grid connection date:</strong> {startDate}</span><br />
                        <span><strong>Running days:</strong> {runningDays}</span><br />
                        <span><strong>Plant Time Zone:</strong> (UTC+08:00) Singapore</span><br />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 d-flex flex-row align-items-center" style={{ borderBottom: '2px solid red', paddingBottom: '4px' }}>
                    <h5>Weather Forecast</h5>
                  </div>
                  
                  <div className="row" >
                    <div className="col" >
                      <div className="row row1">23&deg;</div>
                      <div className="row row2">
                        <img className="img-fluid" style={{ width: '80%' }} src="https://img.icons8.com/windows/100/000000/cloud.png" alt="Weather icon" />
                      </div>
                      <div className="row row3">Partly rain possible</div>
                      <div className="row row3">25~30&deg;</div>
                      <div className="row row3">Wind Speed 6 m/sec</div>
                    </div>
                    <div className="col vertical-line">
                      <div className="row row1">28&deg;</div>
                      <div className="row row2">
                        <img className="img-fluid" style={{ width: '80%' }} src="https://img.icons8.com/ios/100/000000/sun.png" alt="Weather icon" />
                      </div>
                      <div className="row row3">Partly rain possible</div>
                      <div className="row row3">25~30&deg;</div>
                      <div className="row row3">Wind Speed 5 m/sec</div>
                    </div>
                    <div className="col vertical-line">
                      <div className="row row1">27&deg;</div>
                      <div className="row row2">
                        <img className="img-fluid" style={{ width: '80%' }} src="https://img.icons8.com/ios/100/000000/sun.png" alt="Weather icon" />
                      </div>
                      <div className="row row3">Partly rain possible</div>
                      <div className="row row3">25~30&deg;</div>
                      <div className="row row3">Wind Speed 5 m/sec</div>
                    </div>
                    <div className="col vertical-line">
                      <div className="row row1">25&deg;</div>
                      <div className="row row2">
                        <img className="img-fluid" style={{ width: '80%' }} src="https://img.icons8.com/windows/100/000000/cloud.png" alt="Weather icon" />
                      </div>
                      <div className="row row3">Partly rain possible</div>
                      <div className="row row3">25~30&deg;</div>
                      <div className="row row3">Wind Speed 5 m/sec</div>
                    </div>
                    <div className="col vertical-line">
                      <div className="row row1">25&deg;</div>
                      <div className="row row2">
                        <img className="img-fluid" style={{ width: '80%' }} src="https://img.icons8.com/windows/100/000000/cloud.png" alt="Weather icon" />
                      </div>
                      <div className="row row3">Partly rain possible</div>
                      <div className="row row3">25~30&deg;</div>
                      <div className="row row3">Wind Speed 5 m/sec</div>
                    </div>
                    <div style={{ borderBottom: '2px solid red', paddingBottom: '4px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantModal;
