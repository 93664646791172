import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';


const LifetimeChartGraph2 = ({ apiUrl,  plant }) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        yieldSeries: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(apiUrl, {
                    plant,
                
                });
                if (response.status === 200 && response.data) {
                    const { xAxis, 'Yield kWh': yieldData } = response.data.data;

                    setChartData({
                        xAxis,
                        yieldSeries: yieldData,
                    });
                } else {
                    console.error('API response error:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error.message, error.response ? error.response.data : '');
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 300000); // 300,000 ms = 5 minutes
        return () => clearInterval(intervalId);

    }, [apiUrl,plant]);

    const getFontSize = () => {
        const width = window.innerWidth;
         if(width>=1403 && width <= 1540) return 14;
         if(width>=1541 && width <= 1643) return 16;
        if (width >= 1644 && width <= 1852) return 18; 
        if (width >= 1853 && width <= 2529) return 20; 
        if (width >= 2530 && width <= 3795) return 24; 
        if (width >= 3796 && width <= 5060) return 35; 
        if (width >= 5061) return 44; 
        return 12; 
      };

    const getOption = () => ({
        color: ['#00ff00'],
        backgroundColor: 'white',
        grid: {
            left: '3%',
            right: '8%',
            bottom: '3%',
            top: '22%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            textStyle: {
                fontSize: getFontSize(),
                color: 'black', 
              },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        legend: {
            data: ['Yield kWh'],
            textStyle: {
                fontSize: getFontSize(),
                color: 'black', 
              },
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            axisLabel: {
                color: 'black',
                fontSize: getFontSize(),
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                },
            },
        },
        yAxis: {
            type: 'value',
            name: 'Yield kWh',
            nameLocation: 'end',
            nameGap: 15,
            nameTextStyle: {
                color: 'black',
                fontSize: getFontSize(),
                fontWeight: 'bold',
                align: 'center',
            },
            position: 'left',
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                },
            },
            axisLabel: {
                color: 'black',
                fontSize:getFontSize(),
            },
        },
        series: [
            {
                name: 'Yield kWh',
                type: 'bar',
                data: chartData.yieldSeries,
            },
        ],
    });
    const getDynamicHeight = () => {
        const width = window.innerWidth;
        // console.log("width:",width);
     if (width >= 1403 && width <= 1540) return '240px';
      if (width >= 1541 && width <= 1643) return '275px';
      if (width >= 1644 && width <= 1852) return '300px';
      if (width >= 1853 && width <= 2529) return '330px';
      if (width >= 2530 && width <= 3795) return '500px';
      if (width >= 3796 && width <= 5060) return '740px';
      if (width >= 5061) return '900px';
      return '200px';
      };
    return <ReactECharts option={getOption()} style={{height:getDynamicHeight()}}/>;
};

export default LifetimeChartGraph2;
