import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SolarTable.css';

const PPATable = ({ year, yearlyValue, setYearlyValue, yearToDateValue, setYearToDateValue, selectedPlant }) => {
  const [monthlyValues, setMonthlyValues] = useState({});
  const [previousYearlyValue, setPreviousYearlyValue] = useState(0); 
  const [projectedValues, setProjectedValues] = useState({});
  const [projectYearToDate, setProjectYearToDate] = useState('');


  const calculateYearlyValue = (values) => {
    const total = Object.values(values).reduce((acc, value) => {
      const numericValue = parseFloat(String(value).replace(/,/g, '')) || 0;
      return acc + numericValue;
    }, 0);
    return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  const calculateDeviation = (projected, actual) => {
    const parsedProjected = parseFloat(projected.replace(/,/g, ''));
    const parsedActual = parseFloat(actual.replace(/,/g, ''));
    if (!parsedActual || parsedActual === 0) return null;
    return ((parsedActual - parsedProjected) / parsedProjected) * 100;
   };
  
  const calculateYearlyDeviation = (projectedValues, yearlyValue) => {
    const totalProjected = Object.values(projectedValues).reduce((acc, val) => acc + (parseFloat(val) || 0), 0);
    const totalActual = parseFloat(yearlyValue.replace(/,/g, '')) || 0;
    const deviation = totalProjected
      ? ((totalActual - totalProjected) / totalProjected) * 100
      : null;
  
    // Return the deviation with a sign
    return deviation !== null ? deviation.toFixed(2) + '%' : '';
  };
  
  const calculateYearToDateDeviation = (projectYearToDate, yearToDateValue) => {
    const totalProjected = parseFloat(projectYearToDate.replace(/,/g, '')) || 0;
    const totalActual = parseFloat(yearToDateValue.replace(/,/g, '')) || 0;
    const deviation = totalProjected
      ? ((totalActual - totalProjected) / totalProjected) * 100
      : null;
  
   
    return deviation !== null ? deviation.toFixed(2) + '%' : '';
  };

  const getDeviationColor = (deviation) => {
    if (!deviation) return 'black'; 
    const numericValue = parseFloat(deviation);
    return numericValue < 0 ? 'red' : 'green'; 
  };
  

  useEffect(() => {
  
  const calculateProjectedValues = () => {
    return year === '2023' || year === 2023
      ? { Sep: 1798.83, Oct: 1798.83, Nov: 1798.83, Dec: 1798.83 }
      : {
          Jan: 1791.50,
          Feb: 1791.50,
          Mar: 1791.50,
          Apr: 1791.50,
          May: 1791.50,
          Jun: 1791.50,
          Jul: 1791.50,
          Aug: 1791.50,
          Sep: 1791.50,
          Oct: 1791.50,
        };
  };

  const calculateProjectYearToDate = () => {
    return year === '2023' || year === 2023
      ? '7,195.32'
      : year === '2024' || year === 2024
      ? '25,110.32'
      : '0';
  };


  setProjectedValues(calculateProjectedValues());
  setProjectYearToDate(calculateProjectYearToDate());

    const fetchDataForYear = async () => {
      try {
        const response = await axios.post('/fmrequest', { plant: selectedPlant });

        if (response.data.length > 0) {
          const revenueData = response.data[0].Revenue;
          const parsedData = typeof revenueData === 'string' ? JSON.parse(revenueData) : revenueData;

          if (!parsedData || typeof parsedData !== 'object') {
            console.error("Parsed data is not an object or is undefined");
            return;
          }

          const filteredCurrentYearValues = Object.fromEntries(
            Object.entries(parsedData)
              .filter(([key]) => key.endsWith(`-${year.toString().slice(-2)}`))
              .map(([key, value]) => [key.split('-')[0], value["Total Revenue"]?.Amount || 0])
          );

          if (year === 2024) {
            const filteredPreviousYearValues = Object.fromEntries(
              Object.entries(parsedData)
                .filter(([key]) => key.endsWith('-23'))
                .map(([key, value]) => [key.split('-')[0], value["Total Revenue"]?.Amount || 0])
            );

            const totalPreviousYear = calculateYearlyValue(filteredPreviousYearValues);
            setPreviousYearlyValue(parseFloat(totalPreviousYear.replace(/,/g, ''))); 
          }
          setMonthlyValues(filteredCurrentYearValues);
          const totalCurrentYear = calculateYearlyValue(filteredCurrentYearValues);
          setYearlyValue(totalCurrentYear);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataForYear();
    setYearlyValue('');
    setYearToDateValue('');
    setProjectedValues(calculateProjectedValues());
    setProjectYearToDate(calculateProjectYearToDate());
  }, [year, selectedPlant, setYearlyValue, setYearToDateValue]);

  useEffect(() => {
    if (year === 2024) {
      const total2024Numeric = parseFloat(yearlyValue.replace(/,/g, '')) || 0;
      const ytdTotal = previousYearlyValue + total2024Numeric;

      setYearToDateValue(
        ytdTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      );
    } else {
      setYearToDateValue(yearlyValue);
    }
  }, [year, yearlyValue, previousYearlyValue, setYearToDateValue]);
  

  return (
    <div>
      <h5 style={{ fontWeight: 'bold' }}>PPA Revenue (S$) ({year})</h5>

      <table className="solar-table" style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr>
            <th colSpan="4" className="glitterRow" style={{backgroundColor:"grey",color:"white"}}>Monthly</th>
            <th colSpan="3" className="glitterRow" style={{backgroundColor:"grey",color:"white"}}>Yearly ({year})</th>
            <th colSpan="3" className="glitterRow" style={{backgroundColor:"grey",color:"white"}}>Year to Date ({year === '2023' || year === 2023 ? '1 Year' : year === '2024' || year === 2024 ? '2 Years' : ''})</th>
          </tr>
          <tr>
            <th rowSpan={2}>Period</th>
            <th colSpan={2}>Value</th>
            <th rowSpan={2}>Deviation <br></br>(%)</th> 
            <th colSpan={2}>Value</th>
            <th rowSpan={2}>Deviation <br></br>(%)</th>
            <th colSpan={2}>Value</th>
            <th rowSpan={2}>Deviation<br></br>(%)</th>
          </tr>
          <tr>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}} className="glitterRows">Actual</th>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}} className="glitterRows">Actual</th>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}} className="glitterRows">Actual</th>
          </tr>
        </thead>
        <tbody>
          {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => {
            // const projected = parseFloat(projectedValues[month]?.toString().replace(/,/g, '')) || '';
            const projected = projectedValues[month]
            ? parseFloat(projectedValues[month].toString().replace(/,/g, '')).toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') 
            : '';
            const actual = monthlyValues[month]
            ? parseFloat(monthlyValues[month].toString().replace(/,/g, '')).toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') 
            : '';
           
            const deviation = calculateDeviation(projected, actual);
            const formattedDeviation = deviation !== null
          ? deviation < 0
            ? `(${Math.abs(deviation).toFixed(2)})`  
            : `${Math.abs(deviation).toFixed(2)}`   
          : '';
            const deviationStyle = deviation !== null
              ? { color: deviation < 0 ? 'red' : 'green', fontWeight: 'bold' }
              : {};
            return (
              <tr key={month}>
                <td>{month}</td>
                <td style={{ color: "blue", fontWeight: "bold" }}>{projected.toLocaleString()}</td>
                <td style={{ color: "green", fontWeight: "bold" }}>{actual.toLocaleString()}</td>
                <td style={deviationStyle}>
               
                {formattedDeviation}
                </td>
                {index === 0 && (
                  <>
                    {/* <td rowSpan="12" style={{ color: "blue", fontWeight: "bold" }}>
                      {Object.values(projectedValues).reduce((acc, val) => acc + (parseFloat(val) || 0), 0).toLocaleString()}
                    </td> */}
                       <td rowSpan="12" style={{ color: "blue", fontWeight: "bold" }}>
  {Number(
    Object.values(projectedValues).reduce((acc, val) => acc + (parseFloat(val) || 0), 0)
  ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
</td>
                    <td rowSpan="12" style={{ color: "green", fontWeight: "bold" }}>
                      {yearlyValue}
                    </td>
                    
                    

<td rowSpan="12" 
  style={{
    fontWeight: "bold",
    color: getDeviationColor(
      parseFloat(calculateYearlyDeviation(projectedValues, yearlyValue))
    ),
  }}
>
  {(() => {
    const deviation = parseFloat(calculateYearlyDeviation(projectedValues, yearlyValue));
    if (deviation !== null) {
      
      const formattedDeviation = deviation < 0
        ? `(${Math.abs(deviation).toFixed(2)})`  
        : `${Math.abs(deviation).toFixed(2)}`;  
    
      return formattedDeviation;
    }
    return '';  
  })()}
</td>

                    <td rowSpan="12" style={{ color: "blue", fontWeight: "bold" }}>{projectYearToDate}</td>
                    <td rowSpan="12" style={{ color: "green", fontWeight: "bold" }}>
                      {yearToDateValue}
                    </td>
                    
<td rowSpan="12"
  style={{
    fontWeight: "bold",
    color: getDeviationColor(
      parseFloat(calculateYearToDateDeviation(projectYearToDate, yearToDateValue))
    ),
  }}
>
  {(() => {
    const deviation = parseFloat(calculateYearToDateDeviation(projectYearToDate, yearToDateValue));
    if (deviation !== null) {
      
      const formattedDeviation = deviation < 0
        ? `(${Math.abs(deviation).toFixed(2)})`  
        : `${Math.abs(deviation).toFixed(2)}`;  
      
      
      return formattedDeviation;
    }
    return '';  
  })()}
</td>
   
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default PPATable;