
// import React, { useState, useEffect } from 'react';
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// import Login from './components/Login';
// import ReportManagement from './components/Reports/ReportManagementPage';
// import Overview from './components/Overview/Overview';
// import Header from './components/Header';
// import Map from './components/Overview/Map/MapComponent';
// import Finance from './components/FinancialModule/FinanceOverview';
// import UserManagement from './components/UserManagement/UserManagement';
// import AlarmHistory from './components/AlarmManagement/AlarmHistory';
// import PlantManagement from './components/Plants tab/PlantManagement';
// import DeviceManagement from './components/Plants tab/DeviceManagement';


// function App() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [selectedPlant, setSelectedPlant] = useState('Bodyknits');

//   useEffect(() => {
//     const userId = localStorage.getItem('userId');
//     if (userId) {
//       setIsLoggedIn(true);
//     }
//   }, []);

//   const handleLogin = () => {
//     setIsLoggedIn(true);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('userId');
//     setIsLoggedIn(false);
//   };

//   return (
//     <Router>
//       <AppContent 
//         isLoggedIn={isLoggedIn} 
//         setIsLoggedIn={setIsLoggedIn} 
//         selectedPlant={selectedPlant} 
//         setSelectedPlant={setSelectedPlant} 
//         handleLogin={handleLogin} 
//         handleLogout={handleLogout} 
//       />
//     </Router>
//   );
// }

// function AppContent({ isLoggedIn, selectedPlant, setSelectedPlant, handleLogin, handleLogout }) {
//   const location = useLocation();

//   // Only show the header if the user is logged in and not on the login page
//   const shouldShowHeader = isLoggedIn && location.pathname !== '/';

//   return (
//     <div className="App">
//       {shouldShowHeader && <Header selectedPlant={selectedPlant} setSelectedPlant={setSelectedPlant} onLogout={handleLogout} />}
//       <main>
//         <Routes>
//           <Route path="/" element={<Login onLogin={handleLogin} />} />
//           <Route path="/reports" element={<ReportManagement selectedPlant={selectedPlant} />} />
//           <Route path="/overview" element={<Overview selectedPlant={selectedPlant} />} />
    
//           <Route path="/map" element={<Map selectedPlant={selectedPlant} />} />
//           <Route path="/finance" element={<Finance selectedPlant={selectedPlant} />} />
//           <Route path="/userinfo" element={<UserManagement selectedPlant={selectedPlant} />} />
//           <Route path="/alarm" element={<AlarmHistory plant={selectedPlant} />} />
//           <Route path="/plant" element={<PlantManagement />} />
//           <Route path="/device" element={<DeviceManagement />} />
//         </Routes>
//       </main>
//     </div>
//   );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import Login from './components/Login';
import ReportManagement from './components/Reports/ReportManagementPage';
import Overview from './components/Overview/Overview2';
import Header from './components/Header';
import Map from './components/Overview/Map/MapComponent';
import Finance from './components/NEW FM/NewFMOverview';
import UserManagement from './components/UserManagement/UserManagement';
import AlarmHistory from './components/AlarmManagement/AlarmHistory';
import PlantManagement from './components/Plants tab/PlantManagement';
import DeviceManagement from './components/Plants tab/DeviceManagement';

import TuasUserManage from './components/UserManagement/TuasAdminUserManage';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState('');

  
  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedUserType = localStorage.getItem('userType');
    if (storedUserId && storedUserType) {
      setIsLoggedIn(true);
      setUserId(storedUserId);
      setUserType(storedUserType);
      console.log('Fetched from localStorage:', { storedUserId, storedUserType });
    }
  }, []);


  const handleLogin = (userId, userType) => {
    setIsLoggedIn(true);
    setUserId(userId);
    setUserType(userType);

    localStorage.setItem('userId', userId);  
    localStorage.setItem('userType', userType); 
  };

  // console.log('Environment:', process.env.NODE_ENV);

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    setIsLoggedIn(false);
    setUserId(null);
    setUserType(null);
  };

  return (
    <Router>
      <AppContent 
        isLoggedIn={isLoggedIn} 
        userId={userId} 
        userType={userType} 
        selectedPlant={selectedPlant} 
        setSelectedPlant={setSelectedPlant} 
        handleLogin={handleLogin} 
        handleLogout={handleLogout} 
      />
    </Router>
  );
}

function AppContent({ isLoggedIn, userId, userType, selectedPlant, setSelectedPlant, handleLogin, handleLogout }) {
  const location = useLocation();

  const shouldShowHeader = isLoggedIn && location.pathname !== '/';

  return (
    <div className="App">
      {shouldShowHeader && <Header selectedPlant={selectedPlant} setSelectedPlant={setSelectedPlant} onLogout={handleLogout} />}
      <main>
        <Routes>
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/reports" element={<ReportManagement selectedPlant={selectedPlant} userId={userId} userType={userType} />} />
          <Route path="/newoverview" element={<Overview selectedPlant={selectedPlant} userId={userId} userType={userType} />} />
          <Route path="/map" element={<Map selectedPlant={selectedPlant} userType={userType} />} />
          <Route path="/newfinance" element={<Finance selectedPlant={selectedPlant} />} />
          <Route path="/userinfo" element={<UserManagement selectedPlant={selectedPlant} />} />
          <Route path="/alarm" element={<AlarmHistory plant={selectedPlant} userType={userType}/>} />
          <Route path="/plant" element={<PlantManagement />} />
          <Route path="/device" element={<DeviceManagement />} />
          <Route path='/tuasmanage' element={<TuasUserManage/> }/>
        </Routes>
      </main>
    </div>
  );
}

export default App;
