
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const MonthBarChart = ({ apiUrl, LM, plant }) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        irradiationSeries: [],
        yieldSeries: [],
    });
    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.post(apiUrl, { plant, LM });

              if (response.data.success) {
                  const { xAxis, 'Irradiation kWh/m2': irradiationData, 'Yield kWh': yieldData } = response.data.data;

                  setChartData({
                      xAxis: xAxis || [], // Ensure empty arrays if data is missing
                      irradiationSeries: irradiationData || [], // Fallback to empty array
                      yieldSeries: yieldData || [], // Fallback to empty array
                  });
              } else {
                  console.error('API response error:', response.data);
                  // Set empty data on failure
                  setChartData({
                      xAxis: [],
                      irradiationSeries: [],
                      yieldSeries: [],
                  });
              }
          } catch (error) {
              console.error('Error fetching data:', error);
              // Set empty data on error
              setChartData({
                  xAxis: [],
                  irradiationSeries: [],
                  yieldSeries: [],
              });
          }
      };

      fetchData();
      const intervalId = setInterval(fetchData, 300000); // 300,000 ms = 5 minutes
        return () => clearInterval(intervalId);

  }, [apiUrl, LM, plant]);
  
  const getFontSize = () => {
    const width = window.innerWidth;
     if(width>=1403 && width <= 1540) return 14;
     if(width>=1541 && width <= 1643) return 16;
    if (width >= 1644 && width <= 1852) return 18; 
    if (width >= 1853 && width <= 2529) return 20; 
    if (width >= 2530 && width <= 3795) return 24; 
    if (width >= 3796 && width <= 5060) return 35; 
    if (width >= 5061) return 44; 
    return 12; 
  };

    const getOption = () => ({
        color: ['#0000ff', '#ff0000'],
        backgroundColor: 'white',
        grid: {
            left: '3%',
            right: '8%',
            bottom: '3%',
            top: '22%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            textStyle: {
              fontSize: getFontSize(),
              color: 'black', 
            },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        legend: {
            data: ['Irradiation kWh/m²', 'Yield kWh'],
            textStyle: {
              color: 'black',
              fontSize:getFontSize(),
            },
        },
        xAxis: {
            type: 'category',
            data: chartData.xAxis,
            axisLabel: {
                color: 'black',
                fontSize:getFontSize(),
                
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                },
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                },
            },
        },
        yAxis: [
            {
                type: 'value',
                name: 'Yield kWh',
                nameLocation: 'end',
                nameGap: 15, 
                nameTextStyle: {
                  color: 'black',
                  fontSize:getFontSize(),
                  fontWeight: 'bold',
                 align: 'center',
                },
                position: 'left',
                axisLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                  },
                },
                axisLabel: {
                  color: 'black',
                  fontSize:getFontSize(),
                },
              },
              {
                type: 'value',
                name: 'Irradiation kWh/m²',
                nameLocation: 'end',
                nameGap: 15, 
                nameTextStyle: {
                  color: 'black',
                  fontSize: getFontSize(),
                  fontWeight: 'bold',
                  align: 'center',
                
                },
                position: 'right',
                axisLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.2)',
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: 'rgba(255,255,255,.1)',
                  },
                },
                axisLabel: {
                  color: 'black',
                  fontSize:getFontSize(),
                },
              },
            ],
        series: [
            {
                name: 'Irradiation kWh/m²',
                type: 'bar',
                data: chartData.irradiationSeries,
                yAxisIndex: 1,
            },
            {
                name: 'Yield kWh',
                type: 'bar',
                data: chartData.yieldSeries,
                yAxisIndex: 0,
            },
        ],
    });
    const getDynamicHeight = () => {
      const width = window.innerWidth;
      console.log("width:",width);
      
      if (width >= 1403 && width <= 1540) return '240px';
    if (width >= 1541 && width <= 1643) return '275px';
    if (width >= 1644 && width <= 1852) return '300px';
    if (width >= 1853 && width <= 2529) return '350px';
    if (width >= 2530 && width <= 3795) return '500px';
    if (width >= 3796 && width <= 5060) return '740px';
    if (width >= 5061) return '900px';
    return '210px';
    };
    
    return <ReactECharts option={getOption()} style={{height:getDynamicHeight()}} />;
};

export default MonthBarChart;
