
import React, { useEffect, useState } from 'react';
import './AlarmHistory.css';
import Pagination from './Pagination';

const plantNameMapping = {
  'Bodyknits': 'Bodynits',
  'Sweelee': 'SweeLee'
};

const itemsPerPage = 10; 

const AlarmHistory = ({ plant,userType }) => {
  const [alarmData, setAlarmData] = useState([]);
  const [error, setError] = useState(null);
  const [view, setView] = useState('active'); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getDatabaseName = (displayName) => plantNameMapping[displayName] || displayName;

  useEffect(() => {
    const fetchAlarmData = async () => {
      if (view === 'history') {
        try {
          const response = await fetch('/api/alarmhistory', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ plantName: getDatabaseName(plant) }),
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
          }

          const data = await response.json();

          if (Array.isArray(data)) {
            setAlarmData(data);
            setTotalPages(Math.ceil(data.length / itemsPerPage)); 
          } else {
            setError('Unexpected response format');
          }
        } catch (error) {
          console.error('Error fetching alarm history:', error);
          setError(error.message);
        }
      }
    };

    fetchAlarmData();
  }, [plant, view]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAlarms = alarmData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getDisplayPlantName = (plant) => {
     if (userType === '5'|| userType===5) {
        
      switch (plant) {
          case 'Bodyknits':
              return 'Plant A'; 
          case 'Sweelee':
              return 'Plant B';
          default:
              return plant;
      }
  }
    switch (plant) {
        case 'Bodyknits':
            return 'Bodynits';
        case 'Sweelee':
            return 'Swee Lee';
        default:
            return plant;
    } 
};

  return (
    <div className="alarm-history">
      <h1>Alarm Details For {getDisplayPlantName(plant)}</h1>

      <div className="view-toggle">
        <label>
          <input
            type="radio"
            value="active"
            checked={view === 'active'}
            onChange={() => setView('active')}
          />
          Active
        </label>

        <label>
          <input
            type="radio"
            value="history"
            checked={view === 'history'}
            onChange={() => setView('history')}
          />
          History
        </label>
      </div>
      <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
      
      {view === 'history' ? (
        error ? (
          <p className="error-message">{error}</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Plant Name</th>
                  <th>Device Type</th>
                  <th>Device Name</th>
                  <th>Alarm ID</th>
                  <th>Alarm Name</th>
                  <th>Clearance Time</th>
                  <th>Occurrence Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentAlarms.length > 0 ? (
                  currentAlarms.map((alarm, index) => {
                    const displayPlantName = (userType === 5 || userType === '5') 
        ? (alarm.PlantName === 'Bodynits' ? 'Plant A' : 
          alarm.PlantName === 'Sweelee' ? 'Plant B' : alarm.PlantName)
        : alarm.PlantName;
                    return(
                    <tr key={index}>
                      {/* <td>{alarm.PlantName}</td> */}
                      <td>{displayPlantName}</td>
                      <td>{alarm.DeviceType}</td>
                      <td>{alarm.DeviceName}</td>
                      <td className="alarm-id-cell">{alarm.AlarmID}</td>
                      <td>{alarm.AlarmName}</td>
                      <td>{new Date(alarm.ClearanceTime).toLocaleString()}</td>
                      <td>{new Date(alarm.OccurrenceTime).toLocaleString()}</td>
                      <td>{alarm.Status}</td>
                    </tr>
                  )
                })
                ) : (
                  <tr>
                    <td colSpan="8">No alarms found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )
      ) : (
        <p></p> 
      )}

    <div></div>
    </div>
  );
}

export default AlarmHistory;
