// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-main {
    background-color: #cbcaca; 
    height: 100vh; 
  }
.input-group-prepend {
    display: flex;
    align-items: center;
  }
  
  .input-group-text {
    background-color: transparent;
    border: none;
  }
  
  
  #login-error-msg-holder {
    color: red;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .login-form-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn-primary {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
  }
  
  .card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-body {
    padding: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Login.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;EACf;AACF;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,6BAA6B;IAC7B,YAAY;EACd;;;EAGA;IACE,UAAU;IACV,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,aAAa;EACf","sourcesContent":[".login-main {\r\n    background-color: #cbcaca; \r\n    height: 100vh; \r\n  }\r\n.input-group-prepend {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .input-group-text {\r\n    background-color: transparent;\r\n    border: none;\r\n  }\r\n  \r\n  \r\n  #login-error-msg-holder {\r\n    color: red;\r\n    margin-bottom: 10px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .login-form-field {\r\n    width: 100%;\r\n    padding: 10px;\r\n    margin: 10px 0;\r\n    box-sizing: border-box;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .btn-primary {\r\n    width: 100%;\r\n    padding: 10px;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .card {\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .card-body {\r\n    padding: 20px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
