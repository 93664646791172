
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DataTable.css';

const DatePickerComponent = ({ selectedDate, setSelectedDate, reportType }) => {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat={reportType === 'Day' ? 'yyyy-MM-dd' : reportType === 'Month' ? 'yyyy-MM' : 'yyyy'}
      showMonthYearPicker={reportType === 'Month'}
      showYearPicker={reportType === 'Year'}
    />
  );
};

export default DatePickerComponent;
