// import React, { useEffect, useState } from 'react';
// import ReactECharts from 'echarts-for-react';
// import axios from 'axios';

// const LineChartGraph2 = ({ apiUrl, date, plant }) => {
//   const [chartData, setChartData] = useState({ xAxis: [], yieldSeries: [] });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.post(apiUrl, { Date: date, plant });
//         const { xAxis, 'Yield kWh': yieldData } = response.data.data;

//         setChartData({
//           xAxis,
//           yieldSeries: yieldData,
//         });
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//     const intervalId = setInterval(fetchData, 300000); 
//         return () => clearInterval(intervalId);

//   }, [apiUrl, date, plant]);

//   const getFontSize = () => {
//     const width = window.innerWidth;
//      if(width>=1403 && width <= 1540) return 14;
//      if(width>=1541 && width <= 1643) return 16;
//     if (width >= 1644 && width <= 1852) return 18; 
//     if (width >= 1853 && width <= 2529) return 20; 
//     if (width >= 2530 && width <= 3795) return 24; 
//     if (width >= 3796 && width <= 5060) return 35; 
//     if (width >= 5061) return 44; 
//     return 12; 
//   };


// const getOption = () => ({
//     color: ['#32CD32'],
//     backgroundColor: 'white',
//     grid: {
//       left: '8%',
//       right: '6%',
//       bottom: '3%',
//       top: '22%',
//       containLabel: true,
//     },
//     tooltip: {
//       trigger: 'axis',
//       axisPointer: {
//         type: 'cross',
//         label: {
//           backgroundColor: '#6a7985',
//           fontSize:getFontSize(),
//         },
//       },
//       textStyle: {
//         fontSize: getFontSize(),
//         color: 'black', 
//       },
//     },
//     toolbox: {
//       feature: {
//         saveAsImage: {},
//       },
//     },
//     legend: {
//       data: ['Yield kWh'],
//       textStyle: {
//         color: 'black',
//         fontSize:getFontSize(),
//       },
//     },
//     xAxis: {
//       type: 'category',
//       boundaryGap: false,
//       axisLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.2)',
//         },
//       },
//       splitLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.1)',
//         },
        
//       },
//       axisLabel: {
//         color: 'black',
//         rotate: 1,
//         margin: 15,
//         fontSize:getFontSize(),
//       },
//       data: chartData.xAxis,
//     },
//     yAxis: {
//       type: 'value',
//       name: 'Yield kWh',
//       nameLocation: 'end',
//       nameGap: 15,
//       nameTextStyle: {
//         color: 'black',
//         fontSize: getFontSize(),
//         fontWeight: 'bold',
//         align: 'center',
//       },
//       position: 'left',
//       axisLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.2)',
//         },
//       },
//       splitLine: {
//         lineStyle: {
//           color: 'rgba(255,255,255,.1)',
       
//         },
//       },
//       axisLabel: {
//         color: 'black',
//         fontSize:getFontSize(),
//       },
//     },
//     series: [
//       {
//         name: 'Yield kWh',
//         type: 'line',
//         areaStyle: { color: 'rgba(34, 139, 34, 0.7)' }, 
//         data: chartData.yieldSeries,
//       },
//     ],
//   });
//   const getDynamicHeight = () => {
//       const width = window.innerWidth;
//       console.log("width:",width);
      
//       if (width >= 1644 && width <= 1852) return '350px';
//       if (width >= 1853 && width <= 2529) return '450px';
//       if (width >= 2530 && width <= 3795) return '500px';
//       if (width >= 3796 && width <= 5060) return '740px';
//       if (width >= 5061) return '900px';
//       return '300px';
//     };

//   return <ReactECharts option={getOption()} style={{height:getDynamicHeight()}} />;
// };

// export default LineChartGraph2;

import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const LineChartGraph2 = ({ apiUrl, date, plant }) => {
  const [chartData, setChartData] = useState({ xAxis: [], yieldSeries: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(apiUrl, { Date: date, plant });
        const { xAxis, 'Yield kWh': yieldData } = response.data.data;

        setChartData({
          xAxis: xAxis.length ? xAxis : ['No Data'],
          // irradianceSeries: irradianceData.length ? irradianceData : [0], 
          yieldSeries: yieldData.length ? yieldData : [0],
        });
      } catch (error) {
        console.error('Error fetching data:', error);

        setChartData({
          xAxis: ['No Data'], 
          yieldSeries: [0], 
          
        });
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 300000); 
        return () => clearInterval(intervalId);

  }, [apiUrl, date, plant]);

  const getFontSize = () => {
    const width = window.innerWidth;
     if(width>=1403 && width <= 1540) return 14;
     if(width>=1541 && width <= 1643) return 16;
    if (width >= 1644 && width <= 1852) return 18; 
    if (width >= 1853 && width <= 2529) return 20; 
    if (width >= 2530 && width <= 3795) return 24; 
    if (width >= 3796 && width <= 5060) return 35; 
    if (width >= 5061) return 44; 
    return 12; 
  };


const getOption = () => ({
    color: ['#32CD32'],
    backgroundColor: 'white',
    grid: {
      left: '8%',
      right: '6%',
      bottom: '3%',
      top: '22%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
          fontSize:getFontSize(),
        },
      },
      textStyle: {
        fontSize: getFontSize(),
        color: 'black', 
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    legend: {
      data: ['Yield kWh'],
      textStyle: {
        color: 'black',
        fontSize:getFontSize(),
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.2)',
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
        },
        
      },
      axisLabel: {
        color: 'black',
        rotate: 1,
        margin: 15,
        fontSize:getFontSize(),
      },
      data: chartData.xAxis,
    },
    yAxis: {
      type: 'value',
      name: 'Yield kWh',
      nameLocation: 'end',
      nameGap: 15,
      nameTextStyle: {
        color: 'black',
        fontSize: getFontSize(),
        fontWeight: 'bold',
        align: 'center',
      },
      position: 'left',
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.2)',
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
       
        },
      },
      axisLabel: {
        color: 'black',
        fontSize:getFontSize(),
      },
    },
    series: [
      {
        name: 'Yield kWh',
        type: 'line',
        areaStyle: { color: 'rgba(34, 139, 34, 0.7)' }, 
        data: chartData.yieldSeries,
      },
    ],
  });
  const getDynamicHeight = () => {
      const width = window.innerWidth;
      // console.log("width:",width);
      if (width >= 1403 && width <= 1540) return '240px';
      if (width >= 1541 && width <= 1643) return '275px';
      if (width >= 1644 && width <= 1852) return '300px';
      if (width >= 1853 && width <= 2529) return '330px';
      if (width >= 2530 && width <= 3795) return '500px';
      if (width >= 3796 && width <= 5060) return '740px';
      if (width >= 5061) return '900px';
      return '200px';
    };

  return <ReactECharts option={getOption()} style={{height:getDynamicHeight()}} />
};

export default LineChartGraph2;
