import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DeviceManagement.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus,faSearch } from '@fortawesome/free-solid-svg-icons';

const DeviceManagement = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingDeviceId, setEditingDeviceId] = useState(null);
  const [updatedDevice, setUpdatedDevice] = useState({});
  const [newDevice, setNewDevice] = useState({
    DeviceStatus: '',
    Name: '',
    PlantName: '',
    DeviceType: '',
    SerialNumber: '',
    DeviceModel: '',
    DeviceDn: '',
    Plant: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [searchQuery, setSearchQuery] = useState('');


  // Fetch device data
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get('/device/info');
        setDevices(response.data);
      } catch (error) {
        setError('Failed to fetch device information.');
      } finally {
        setLoading(false);
      }
    };
    fetchDevices();
  }, []);

  // Handle Edit
  const handleEdit = (deviceId) => {
    setEditingDeviceId(deviceId);
    const device = devices.find((d) => d.DeviceId === deviceId);
    setUpdatedDevice(device);
  };

  // Handle Save Edit
  const handleSave = async () => {
    try {
      await axios.put(`/device/update/${editingDeviceId}`, updatedDevice);
      setDevices((prevDevices) =>
        prevDevices.map((device) =>
          device.DeviceId === editingDeviceId ? updatedDevice : device
        )
      );
      setEditingDeviceId(null);
    } catch (error) {
      console.error('Failed to update device:', error.message);
    }
  };

  // Handle Delete
  const handleDelete = async (deviceId) => {
    if (window.confirm('Are you sure you want to delete this device?')) {
      try {
        await axios.delete(`/device/delete/${deviceId}`);
        setDevices(devices.filter((device) => device.DeviceId !== deviceId));
      } catch (error) {
        console.error('Failed to delete device:', error.message);
      }
    }
  };

  // Handle Create Device
  const handleCreateDevice = async () => {
    try {
      await axios.post('/device/create', newDevice);
      setSuccessMessage('Device created successfully!');
    
      const updatedDeviceInfoResponse = await axios.get('/device/info');

      setDevices(updatedDeviceInfoResponse.data);

      setNewDevice({
        DeviceStatus: '',
        Name: '',
        PlantName: '',
        DeviceType: '',
        SerialNumber: '',
        DeviceModel: '',
        DeviceDn: '',
        Plant: '',
      });
      setTimeout(() => {
        setSuccessMessage('');
        setIsModalOpen(false);
      }, 3000);
    } catch (error) {
      console.error('Failed to add device:', error.message);
    }
  };

  // Handle Input Change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editingDeviceId) {
      setUpdatedDevice({ ...updatedDevice, [name]: value });
    } else {
      setNewDevice({ ...newDevice, [name]: value });
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const filteredDevice = devices.filter((device) =>
    device.PlantName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className="device-management">
      <h3 className='device-head'>Device Management</h3>

      <div className="device-container">
     <div className="search-bar">
 
    <input
      type="text"
      placeholder="Search by plant name"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
     <span className="search-icon">
      <FontAwesomeIcon icon={faSearch} />
    </span>
  </div>

      <button className="device-create" onClick={handleOpenModal}>
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px' }} />
        Add Device
      </button>
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      <table className="device-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Device Status</th>
            {/* <th>Name</th> */}
            <th>Plant Name</th>
            <th> Device Type</th>
            <th>Serial Number</th>
            <th>Device Model</th>
            <th>Device DN</th>
            {/* <th>Plant</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {filteredDevice.length > 0 ? (
          filteredDevice.map((device,index) => (
            <tr key={device.DeviceId}>
              <td>{index + 1}</td>
              <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="DeviceStatus"
                    value={updatedDevice.DeviceStatus}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.DeviceStatus
                )}
              </td>
              {/* <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="Name"
                    value={updatedDevice.Name}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.Name
                )}
              </td> */}
              <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="PlantName"
                    value={updatedDevice.PlantName}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.PlantName
                )}
              </td>
              <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="DeviceType"
                    value={updatedDevice.DeviceType}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.DeviceType
                )}
              </td>
              <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="SerialNumber"
                    value={updatedDevice.SerialNumber}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.SerialNumber
                )}
              </td>
              <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="DeviceModel"
                    value={updatedDevice.DeviceModel}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.DeviceModel
                )}
              </td>
              <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="DeviceDn"
                    value={updatedDevice.DeviceDn}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.DeviceDn
                )}
              </td>
              {/* <td>
                {editingDeviceId === device.DeviceId ? (
                  <input
                    type="text"
                    name="Plant"
                    value={updatedDevice.Plant}
                    onChange={handleInputChange}
                  />
                ) : (
                  device.Plant
                )}
              </td> */}
              <td className='update-buttons'>
                {editingDeviceId === device.DeviceId ? (
                  <>
                    <button className="device-save" onClick={handleSave}>Save</button>
                    <button className="device-update-cancel" onClick={() => setEditingDeviceId(null)}>Cancel</button>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => handleEdit(device.DeviceId)}
                      style={{ cursor: 'pointer', marginRight: '15px', color: 'blue',  fontSize:'22px'}}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleDelete(device.DeviceId)}
                      style={{ cursor: 'pointer', color: 'red',fontSize:'20px'}}
                    />
                  </>
                )}
              </td>
            </tr>
          ))
         ) : (
              <tr>
                <td colSpan="100%" style={{ textAlign: "center" }}>
                  No data found for the search criteria
                </td>
              </tr>
          )}
          
        </tbody>
      </table>

      {isModalOpen && (
        <div className="device-create-modal">
          <div className="device-modal-content">
            <h3>Add New Device</h3>
            <form>
              <div>
                <label>Device Status:</label>
                <input
                  type="text"
                  name="DeviceStatus"
                  value={newDevice.DeviceStatus}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div>
                <label>Name:</label>
                <input
                  type="text"
                  name="Name"
                  value={newDevice.Name}
                  onChange={handleInputChange}
                />
              </div> */}
              <div>
                <label>Plant Name:</label>
                <input
                  type="text"
                  name="PlantName"
                  value={newDevice.PlantName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label> Device Type:</label>
                <input
                  type="text"
                  name="DeviceType"
                  value={newDevice.DeviceType}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Serial Number:</label>
                <input
                  type="text"
                  name="SerialNumber"
                  value={newDevice.SerialNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label> Device Model:</label>
                <input
                  type="text"
                  name="DeviceModel"
                  value={newDevice.DeviceModel}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label>Device DN:</label>
                <input
                  type="text"
                  name="DeviceDn"
                  value={newDevice.DeviceDn}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div>
                <label>Plant:</label>
                <input
                  type="text"
                  name="Plant"
                  value={newDevice.Plant}
                  onChange={handleInputChange}
                />
              </div> */}
              <button type="button" style={{ backgroundColor: "#4CAF50" }} onClick={handleCreateDevice}>
                Add Device
              </button>
              <button type="button" style={{ backgroundColor: "#535553" }} onClick={handleCloseModal}>
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceManagement;
