
import React, { useState, useEffect, useMemo, useCallback} from 'react';
import ReportTypeSelector from './ReportTypeSelector';
import DatePickerComponent from './DatePickerComponent';
import DataTableComponent from './DataTableComponent';
import axios from 'axios';
import { format } from 'date-fns';
import loadingImage from '../../img/loading-icon.jpg'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './DataTable.css';
import { downloadCSV, downloadJSON, downloadXML, downloadTXT, downloadSQL, downloadExcel } from './FileDownloads';

// const userType= localStorage.getItem('userType');

const ReportManagementPage = ({ selectedPlant,userType }) => {
  const [selectedReportType, setSelectedReportType] = useState('Day');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [showOptions, setShowOptions] = useState(false);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [shouldCheckPerformance, setShouldCheckPerformance] = useState(false);

 const [totalPowerData, setTotalPowerData] = useState(null); 
 const [noData, setNoData] = useState(false);

//console.log("Report userType:", userType);

  //   const fetchReportData = async (reportType, date) => {
      
  //     if (loading) return; // Prevent multiple fetch calls
  //     setLoading(true);
  
  //     const endpoints = {
  //       Day: '/api/dayreports',
  //       Month: '/api/monthreports',
  //       Year: '/api/yearreports',
  //       LifeTime: '/api/lifetimereports',
  //     };
  
  //     const endpoint = endpoints[reportType];
  //     const payload = { plant: selectedPlant };
  
  //     if (reportType === 'Day') {
  //       payload.date = format(date, 'yyyy-MM-dd');
  //     } else if (reportType === 'Month') {
  //       payload.month = format(date, 'yyyy-MM');
  //     } else if (reportType === 'Year') {
  //       payload.year = date.getFullYear();
  //     }
  
  //   //   try {
  //   //     const response = await axios.post(endpoint, payload);
  //   //     if (Array.isArray(response.data.data)) {
  //   //       setData(response.data.data);
  //   //     }
  //   //   } catch (error) {
  //   //     console.error('Error fetching data:', error);
  //   //   } finally {
  //   //     setLoading(false);
  //   //   }
  //   // };
  //   try {
  //     const response = await axios.post(endpoint, payload);
  //     const fetchedData = response.data.data;
      
  //     if (Array.isArray(fetchedData) && fetchedData.length > 0) {
  //       setData(fetchedData);
  //       setNoData(false); // Data is available
  //     } else {
  //       setData([]); // Clear existing data
  //       setNoData(true); // No data available
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setNoData(true); // Handle fetch error as no data
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  
  //   useEffect(() => {
  //     fetchReportData(selectedReportType, selectedDate);
  //   }, [selectedReportType, selectedDate,selectedPlant]);



  const fetchReportData = useCallback(async (reportType, date) => {
    setLoading(true);
    setNoData(false); 

    if (!selectedPlant) {
        setNoData(true);
        setLoading(false);
        return;
    }

    const endpoints = {
        Day: '/api/dayreports',
        Month: '/api/monthreports',
        Year: '/api/yearreports',
        LifeTime: '/api/lifetimereports',
    };

    const endpoint = endpoints[reportType];
    const payload = { plant: selectedPlant };

    if (reportType === 'Day') {
        payload.date = format(date, 'yyyy-MM-dd');
    } else if (reportType === 'Month') {
        payload.month = format(date, 'yyyy-MM');
    } else if (reportType === 'Year') {
        payload.year = date.getFullYear();
    }

    try {
        const response = await axios.post(endpoint, payload);
        const fetchedData = response.data.data;

        if (Array.isArray(fetchedData) && fetchedData.length > 0) {
            setData(fetchedData);
            setNoData(false);
        } else {
            setData([]);
            setNoData(true);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
        setNoData(true);
    } finally {
        setLoading(false);
    }
}, [selectedPlant]);

useEffect(() => {
    setData([]); 
    setNoData(false); 

    if (selectedPlant) {
        fetchReportData(selectedReportType, selectedDate);
    } else {
        setNoData(true);
    }
}, [selectedReportType, selectedDate, fetchReportData, selectedPlant]);
  
  
    const handleReportTypeChange = (newReportType) => {
      setSelectedReportType(newReportType);
      const currentDate = new Date();
      if (newReportType === 'Month') {
        setSelectedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
      } else if (newReportType === 'Year') {
        setSelectedDate(new Date(currentDate.getFullYear(), 0, 1));
      } else {
        setSelectedDate(currentDate);
      }
    };
  

  
  const columns = useMemo(() => {
    switch (selectedReportType) {
      case 'Day':
        return [
          { Header: 'Statistical Period', accessor: 'Statistical Period' },
          { Header: 'Hourly Irradiance (kWh/m²)', accessor: 'Hourly Irradiance' },
          { Header: 'Theoretical Yield (kWh)', accessor: 'Theoretical Yield (kWh)' },
          { Header: 'PV Yield (kWh)', accessor: 'PV Yield (kWh)' },
          { Header: 'Inverter Yield (kWh)', accessor: 'Inverter Yield (kWh)' },
        ];
      case 'Month':
        return [
          { Header: 'Statistical Period', accessor: 'Statistical Period' },
          { Header: 'Daily Irradiance (kWh/m²)', accessor: 'Daily Irradiance (kWh/m²)' },
          { Header: 'Theoretical Yield (kWh)', accessor: 'Theoretical Yield (kWh)' },
          { Header: 'PV Yield (kWh)', accessor: 'PV Yield (kWh)' },
          { Header: 'Inverter Yield (kWh)', accessor: 'Inverter Yield (kWh)' },
          { Header: 'Performance Ratio (%)', accessor: 'Performance_ratio (%)' },
        ];
      case 'Year':
      case 'LifeTime':
        return [
          { Header: 'Statistical Period', accessor: 'Statistical Period' },
          { Header: 'Daily Irradiance (kWh/m²)', accessor: 'Daily Irradiance' },
          { Header: 'Theoretical Yield (kWh)', accessor: 'Theoretical Yield (kWh)' },
          { Header: 'PV Yield (kWh)', accessor: 'PV Yield (kWh)' },
          { Header: 'Inverter Yield (kWh)', accessor: 'Inverter Yield (kWh)' },
          { Header: 'Performance Ratio (%)', accessor: 'Performance_ratio (%)' },
        ];
      default:
        return [];
    }
  }, [selectedReportType]);

  

//Export data
  const handleIconClick = () => {
    setShowOptions(!showOptions);
  };

  const handleDownload = (downloadFunction) => {
    let formattedDate = '';
    switch (selectedReportType) {
      case 'Day':
        formattedDate = format(selectedDate, 'yyyy-MM-dd');
        break;
      case 'Month':
        formattedDate = format(selectedDate, 'yyyy-MM');
        break;
      case 'Year':
        formattedDate = format(selectedDate, 'yyyy');
        break;
      case 'LifeTime':
        formattedDate = 'Lifetime';
        break;
      default:
        formattedDate = format(selectedDate, 'yyyy-MM-dd');
        break;
    }
    downloadFunction(data, getDisplayPlantName(selectedPlant), formattedDate);
    setShowOptions(false);
  };
//performance ratio
  const checkPerformanceRatio = useCallback(() => {
    const plantName = getDisplayPlantName(selectedPlant);
    const referenceValue = plantName === 'Bodynits' ? 79.5 : plantName === 'Swee Lee' ? 74.4 : null;

    if (!referenceValue) {
      console.warn('Plant name not recognized.');
      return [];
    }

    const threshold = referenceValue - 5;
    const rowsToHighlight = data.map((row, index) => {
      const performanceRatio = parseFloat(row['Performance_ratio (%)']);
      if (!isNaN(performanceRatio) && performanceRatio < threshold) {
        return index;
      }
      return null;
    }).filter(index => index !== null);

    setHighlightedRows(rowsToHighlight);

    if (rowsToHighlight.length > 0) {
      alert('If performance ratio drops by 5% below the reference value, rows are highlighted in red');
    } else {
      alert('No rows dropped below 5% of the reference value.');
    }
  }, [data, selectedPlant]);

  useEffect(() => {
    if (shouldCheckPerformance) {
      checkPerformanceRatio();
      setShouldCheckPerformance(false);
    }
  }, [shouldCheckPerformance, checkPerformanceRatio]);

 // Clear highlighted 
  useEffect(() => {
    setHighlightedRows([]);
  }, [selectedReportType, selectedDate,selectedPlant]);



  const handlePerformanceAlertClick = () => {
    setShouldCheckPerformance(true);
  };


  //-------------------------------------------------------


  const fetchTotalPowerData = useCallback(async () => {
  
    const totalPowerEndpoints = {
      Day: '/api/daytotalpower',
      Month: '/api/monthtotalpower',
      Year: '/api/yeartotalpower',
      LifeTime: '/api/lifetimetotalpower'
    };
    
    const totalPowerEndpoint = totalPowerEndpoints[selectedReportType];
    const requestPayload = { plant: selectedPlant };
    if (selectedReportType === 'Day') {
      requestPayload.date = format(selectedDate, 'yyyy-MM-dd');
    } else if (selectedReportType === 'Month') {
      requestPayload.date = format(selectedDate, 'yyyy-MM'); 
    } else if (selectedReportType === 'Year') {
      requestPayload.date = format(selectedDate, 'yyyy'); 
    } else if (selectedReportType === 'LifeTime') {
      delete requestPayload.date;
    }
  
    try {
      const response = await axios.post(totalPowerEndpoint, requestPayload);
      const data = response.data; 
       console.log('Fetched data:', data);
      setTotalPowerData(selectedReportType === 'LifeTime' ? [data] : [data]);
    } catch (error) {
      console.error('Error fetching total power data:', error.response ? error.response.data : error.message);
    }
  }, [selectedReportType, selectedDate, selectedPlant]);
  

  const totalPowerKey = selectedReportType === 'LifeTime' 
  ? 'LifetimeTotalPower' 
  : `${selectedReportType}TotalPower`;


const totalPowerValue = totalPowerData && totalPowerData.length > 0 
  ? totalPowerData[0][totalPowerKey] 
  : '0';

  const displayUnit = selectedReportType === 'Day' ? 'kWh' : 'MWh';

  
  useEffect(() => {
    fetchTotalPowerData();
  }, [ fetchTotalPowerData]);


  
  const getDisplayPlantName = (plant, userType) => {
    if (userType === '4' || userType===4) {
        return 'Plant A';
    } else if (userType === '5'|| userType=== 5) {
        
      switch (plant) {
          case 'Bodyknits':
              return 'Plant A'; 
          case 'Sweelee':
              return 'Plant B';
          default:
              return plant;
      }
  }
    
    switch (plant) {
        case 'Bodyknits':
            return 'Bodynits';
        case 'Sweelee':
            return 'Swee Lee';
        default:
            return plant;
    } 
};
const displayPlantName = getDisplayPlantName(selectedPlant, userType);
  
  return (
    <div className="content">
      <div>
        <div className="table-title header-left">
          <h3>
              <strong>
        <span id="Plant2">{displayPlantName}</span>  Report
    </strong>
            
          </h3>
        </div>
      </div>
      <div className="total-power">
   <h6 style={{color:"green"}}>TOTAL POWER METER'S YIELD</h6>
   {totalPowerValue} {displayUnit}
  </div>

      
      <div className="report-table" style={{ marginTop: '40px' }}>
        <ReportTypeSelector
          selectedReportType={selectedReportType}
          // setSelectedReportType={setSelectedReportType}
          setSelectedReportType={handleReportTypeChange}
        />
        <DatePickerComponent
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          reportType={selectedReportType}
        />
        {(userType!== 4 && userType!=='5' && userType!==5 &&
        <button id="alertButton" onClick={handlePerformanceAlertClick}>Performance Alert</button>
        )}
      <div className="download-container">
          <FontAwesomeIcon
            icon={faDownload}
            aria-hidden="true"
            style={{ cursor: 'pointer', width: '40px', height: '40px' }}
            onClick={handleIconClick}
            title="Export Data" 
          />
          {showOptions && (
            <div className="download-options">
              <div onClick={() => handleDownload(downloadCSV)}>CSV</div>
              <div onClick={() => handleDownload(downloadJSON)}>JSON</div>
              <div onClick={() => handleDownload(downloadXML)}>XML</div>
              <div onClick={() => handleDownload(downloadTXT)}>TXT</div>
              <div onClick={() => handleDownload(downloadSQL)}>SQL</div>
              <div onClick={() => handleDownload(downloadExcel)}>Excel</div>
            </div>
          )}
        </div>
      </div>
       {loading ? (
        <div className="loading-indicator">
          <img src={loadingImage} alt="Loading..." />
        </div>
      ) : noData ? (
        <p style={{fontSize:"22px", fontWeight:'bold'}}>No data found for the selected date and report type.</p>
      ) : (
        <div style={{ marginTop: '25px' }}>
          <DataTableComponent columns={columns} data={data} highlightedRows={highlightedRows} />
        </div>
      )} 

    </div>
    
  );
};

export default ReportManagementPage;
