import React from 'react';
import '../OverviewStyle.css'; 

const EnergyValueGraph2 = ({ viewOption, energyValue }) => {
    return (
        <div className="energy-value-container">
            <h6>
                {viewOption} Yield: <strong>{energyValue}</strong> {viewOption === 'Daily' ? 'kWh' : 'MWh'}
            </h6>
            
        </div>
    );
};

export default EnergyValueGraph2;
